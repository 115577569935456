import React from 'react';
import {
    FlatList,
    Image,
    SafeAreaView,
    ScrollView,
    StatusBar,
    StyleSheet,
    Text,
    useColorScheme,
    View,
    Button
} from 'react-native';
import { Card } from 'react-native-paper'
import MatchItem from './components/matchitem';
import firebase from 'firebase';
import ScheduledMatchItem from './components/scheduledmatchitem';

const tempData = [{
    matchName:"My Test Stream",
    //matchDescription:"Jack's first test of the stream",
    matchDate: new Date().toISOString(),
    streamKey: "k8xm-thmt-1usc-e1km-6a3b",
    streamURL: "rtmp://x.rtmp.youtube.com/live2/",
    publicStreamURL: "", //when i can get from the api..
}]

class ScheduledMatches extends React.Component {

    state={
        matchList: []
    }

    componentDidMount(){
        // Get request for current matches
        //then set to state.
        this.props.navigation.setOptions({ title: 'Match List' })
        firebase.database().ref("seasons").limitToLast(1).orderByChild("isActive").equalTo(true).once("value",(data)=>{
            console.log(data.val())
            var seasonId = Object.keys(data.val())[0]
            firebase.database()
            .ref("seasons").child(seasonId).child("teams").once("value", (data) => {
                console.log(data.val())

                this.setState({
                    teamList: data.val() == null ? [] : data.val(),
                })
            })
            firebase.database().ref("matches").orderByChild("seasonKey").equalTo(seasonId).once("value", (match)=>
            {
                if(match.val()){
                  console.log(match.val())
                var matchList = Object.keys(match.val()).map((matchId, index)=>{
                    return {...match.val()[matchId], matchid: matchId}
                })
                this.setState({matchList: matchList})  
                }
                else{
                    this.setState({matchList:[], noMatches: true})
                }
                
            }) 
            
        })

       // this.setState({matchList: tempData})
    }

    render(){
        return(<View style={{width:"100%", height:"100%", backgroundColor:"#ff7800"}}>
            {this.state.noMatches ? 
            <Card style={{borderRadius:15, margin:3, }}>
                <Text style={{fontSize:24, textAlign:"center"}}>There are no matches scheduled.</Text>
            </Card> : null}
            <FlatList data={this.state.matchList} renderItem={(data) =>{
                return(
                    <ScheduledMatchItem {...data.item} key={data.index} {...this.props} hostTeamName={this.state.teamList[data.item.TeamAName].teamName} awayTeamName={this.state.teamList[data.item.TeamBName].teamName} />
                )
            }} />
            
        </View>
            
        )
    }
}
export default ScheduledMatches