import React from 'react';
import {
    Image,
    SafeAreaView,
    ScrollView,
    StatusBar,
    StyleSheet,
    Text,
    useColorScheme,
    View,
} from 'react-native';
//import { LOADING } from '../../languages';
import { LAPingPongWebHeader } from '../classes/images';
import SpinningBall from '../components/spinningloadingball';

function LoadingScreen() {

    return (
        <SafeAreaView>
            <View style={{
                height: "100%",
                width: "100%",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <SpinningBall></SpinningBall>
                {
                    /*
                    <Image style={{
                    resizeMode: 'contain', flex: 1,
                    width: "100%",
                    height: null,
                    resizeMode: 'contain',
        
                }} source={LAPingPongWebHeader()} />
                    */
                }
                <Text>{"Loading"}</Text>
            </View>
        </SafeAreaView>
    )
}

export default LoadingScreen