import React from 'react';
import {
  FlatList,
  Image,
  SafeAreaView,
  ScrollView,
  StatusBar,
  StyleSheet,
  Text,
  useColorScheme,
  View,
  Button,
  TouchableOpacity,
  Linking
} from 'react-native';
import axios from 'axios';
import NewsArticle from './components/article';
import { LatestNewsURL } from './settings';
import { LAPPWordPressPosts } from './classes/class.wordpressjson'
import Icon from 'react-native-vector-icons/FontAwesome5';
import LoadingScreen from './login/loadingscreen';
import SpinningBall from './components/spinningloadingball';
import firebase from 'firebase';
class LatestPosts extends React.Component {

  state = {
    LatestPosts: [],
    initialLoadDone: false,
  }



  componentDidMount() {
    axios.get(LatestNewsURL).then(async (data) => {

      console.log(data.data)
      var formatted = new LAPPWordPressPosts(data.data)
      var promiseList = []
      for (const post of formatted.formattedPosts) {
        var promisedPhotos = axios.get(post["mediaURL"])
        promiseList.push(promisedPhotos)
      }
      var allResponses = await Promise.all(promiseList)
      for (const res of allResponses) {
        for (const photo of res.data) {
          formatted.addPhotoLinkToPost(photo["post"], photo["source_url"])
        }
      }

      console.log(formatted.formattedPosts)
      this.setState({
        doneLoading: true,
        LatestPosts: formatted.formattedPosts,
        initialLoadDone: true
      })
    })



  }

  render() {
    if (this.state.initialLoadDone) {

    }
    return (

      <View style={{ width: "100%", height: "100%", backgroundColor: "#ff7800" }}>
        <View style={{ flexDirection: "row", justifyContent: "space-between", }}>
          <View style={{ width: "33%" }}>
            <Icon.Button onPress={() => {
              this.props.navigation.navigate('ScheduledMatches');
            }} color="black" backgroundColor="transparent" name="calendar-day" style={{ flexDirection: "column", backgroundColor: "white", borderColor: "black", width: "100%", borderWidth: 3, margin: 2 }} >
              <Text style={{ color: "black" }}>Schedule</Text>
            </Icon.Button>
          </View>
          <View style={{ width: "33%" }} >
            <Icon.Button onPress={() => {
                 firebase.auth().onAuthStateChanged((user) => {
                  if (user) {
                    console.log(user)
                    firebase.database().ref("admins").once("value",(admins)=>{
                      Object.keys(admins.val()).map((adminKey) =>{
                        if(user.email == admins.val()[adminKey]){
                          this.props.navigation.navigate('MatchList',  {screen:'MatchList', params:{isAdmin: true}});
                        }
                        else{
                          this.props.navigation.navigate('MatchList',  {screen:'MatchList', params:{isAdmin: true}});
                        }
                      })
                    })
                    
                  }
                  else {
                    this.props.navigation.navigate('LoginScreen',{isAdmin:false});
                  }
                })

              //
            }} color="black" backgroundColor="transparent" name="video" style={{ flexDirection: "column", backgroundColor: "white", borderColor: "black", borderWidth: 3, margin: 2 }} >
              <Text>Live Stream</Text>
            </Icon.Button>
          </View>
          <View style={{ width: "33%" }} >
            <Icon.Button onPress={() => {
              Linking.openURL("mailto:info@lapingpong.com?subject=LA%20Ping%20Pong")
            }} color="black" backgroundColor="transparent" name="envelope" style={{ flexDirection: "column", backgroundColor: "white", borderColor: "black", borderWidth: 3, margin: 2 }} >
              <Text>Contact Us</Text>
            </Icon.Button>
          </View>
        </View>
        {
          this.state.initialLoadDone ?
            <FlatList data={this.state.LatestPosts} renderItem={(data) => {
              return <NewsArticle article={data.item} index={data.index}></NewsArticle>
            }} />
            :
            <View style={{ width: "100%", height: "100%", justifyContent: "center", alignItems: "center" }}>
              <SpinningBall></SpinningBall>
            </View>

        }

      </View>


    )
  }

}

export default LatestPosts