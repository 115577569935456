import React from 'react';
import { Image, Animated, Easing, Text, ScrollView, FlatList, Button, Linking} from 'react-native'
import { Card } from 'react-native-paper'
import { parse } from 'node-html-parser';


function NewsArticle(props){
    var title = parse(props.article.title).rawText
    var cleanedUpExcerpt = parse(props.article.excerpt).innerText
    console.log(props)
    console.log(props.article.photoURLList[0])
    return(
        <Card style={{borderRadius:15, margin:3, backgroundColor:"white",maxWidth:600, alignSelf:"center", width:"100%"}}>
            <Card.Title 
            
            title={title}
            titleStyle={{fontSize:16, fontWeight:"bold"}}
            ></Card.Title>
            <Card.Content>
                {
                    props.article.photoURLList.length > 0 ? 
                    <Card.Cover height="100%" width="100%" source={{uri: props.article.photoURLList[0]}} />:
                    null
                }
                <Text>{cleanedUpExcerpt}</Text>
                
            </Card.Content>
            <Card.Actions style={{justifyContent:"flex-end" }} >
                <Button color="#ff7800"
                 title={"Continue Reading..."}
                 onPress={()=>{
                     Linking.openURL(props.article.link)
                 }}
                 >
                    
                </Button>
            </Card.Actions>
            <Image></Image>
        </Card>
    )
}
export default NewsArticle