import React from 'react';
import {
    FlatList,
    Image,
    SafeAreaView,
    ScrollView,
    StatusBar,
    StyleSheet,
    Text,
    useColorScheme,
    View,
    //Button,
    Linking
} from 'react-native';
import { AddIcon, IconButton, NativeBaseProvider, Modal, FormControl, Input, Select } from 'native-base';

import MatchItem from './components/matchitem';
import firebase from 'firebase';
import { Card, Button } from 'react-native-paper';

// const tempData = [{
//     matchName:"My Test Stream",
//     //matchDescription:"Jack's first test of the stream",
//     matchDate: new Date().toISOString(),
//     streamKey: "k8xm-thmt-1usc-e1km-6a3b",
//     streamURL: "rtmp://x.rtmp.youtube.com/live2/",
//     publicStreamURL: "", //when i can get from the api..
// }]

class DivisionAdmin extends React.Component {

    state = {
        matchList: [],
        isAdmin: false,
        divisionList: [],
        rows: [],
        newDivisionName:"",
        newCoordinatorName:""
    }

    componentDidMount() {
        // Get request for current matches
        //then set to state.
        firebase.database()
            .ref("seasons").child(this.props.route.params["seasonKey"]).child("divisions").on("value", (data) => {
                console.log(data.val())

                this.setState({
                    divisionList: data.val() == null ? [] : data.val()
                })
            })

        // this.setState({matchList: tempData})
    }

    render() {
        return (<NativeBaseProvider>
            <View style={{ width: "100%", height: "100%", backgroundColor: "#ff7800" }} >
                <ScrollView style={{ width: "100%", height: "100%", backgroundColor: "#ff7800" }}>

                    {
                        Object.keys(this.state.divisionList).map((key, index) => {
                            console.log(key)
                            console.log(this.state.divisionList)
                            let division = this.state.divisionList[key]
                            return (
                                <View style={{ padding: 5 }} key={key}>
                                    <Card style={{ padding: 5, flexDirection: "column" }} >
                                        <Text style={{ fontSize: 24, padding: 5, fontWeight: "bold" }}>{this.state.divisionList[key].divisionName}</Text>
                                        <Text>Coordinator: {division["coordinatorName"]}</Text>


                                    </Card>
                                </View>

                            )
                        })
                    }

                </ScrollView>
                <IconButton
                    onPress={() => {
                        this.setState({ showNewDivision: true })
                    }}
                    borderRadius={100} color={"white"} justifyContent={"center"} alignItems={"center"} position={"absolute"} bottom={5} right={5} bg="red.500" width={"1/5"} maxWidth={60} icon={<AddIcon   ></AddIcon>}>

                    </IconButton>

                    <Modal isOpen={this.state.showNewDivision} onClose={() => {
                        console.log("pressed")
                        this.setState({ showNewDivision: false })
                    }}>
                        <Modal.Content>
                            <Modal.CloseButton
                            ></Modal.CloseButton>
                            <Modal.Header><Text>Add New Team</Text></Modal.Header>
                            <Modal.Body>
                                <FormControl>
                                    <FormControl.Label>Division Name:</FormControl.Label>
                                    <Input
                                        onChangeText={(text) => {
                                            this.setState({ newDivisionName: text })
                                        }}
                                        value={this.state.newDivisionName}
                                        placeholder='Division Name'></Input>
                                </FormControl>
                                <FormControl>
                                    <FormControl.Label>Coordinator:</FormControl.Label>
                                    <Input
                                        onChangeText={(text) => {
                                            this.setState({ newCoordinatorName: text })
                                        }}
                                        value={this.state.newCoordinatorName}
                                        placeholder='Coordinator Name'></Input>
                                </FormControl>
                               
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    disabled={ this.state.newDivisionName == ""}
                                    onPress={() => {
                                        firebase.database()
                                            .ref("seasons").child(this.props.route.params["seasonKey"]).child("divisions").push({ divisionName: this.state.newDivisionName, coordinatorName: this.state.newCoordinatorName })
                                        this.setState({ newDivisionName: "", newCoordinatorName:"", showNewDivision: false })
                                    }}
                                >
                                    <Text>Save</Text>
                                </Button>
                            </Modal.Footer>
                        </Modal.Content>

                    </Modal>
            </View>
        </NativeBaseProvider>

        )
    }
}
export default DivisionAdmin