import React from 'react';
import {
    FlatList,
    Image,
    SafeAreaView,
    ScrollView,
    StatusBar,
    StyleSheet,
    //Text,
    useColorScheme,
    View,
    
} from 'react-native';
import { Input, NativeBaseProvider, Text, Button } from 'native-base'
import { Card, ActivityIndicator } from 'react-native-paper';
import firebase from 'firebase';



export default class LoginScreen extends React.Component {
    state={
        email:"",
        password:"",
        isLoading:false
    }

    render(){
        return (
            <NativeBaseProvider>
            <View style={{width:"100%", height:"100%",alignItems:"center"}}>
                <View style={{height:"10%"}}></View>
                <Card style={{
                    width:"70%",
                    height:"auto",


                }}>
                    <View style={{margin:10}}>
                        <Input 
                        onChangeText={(text)=>{
                            this.setState({email: text})
                        }}
                        style={{margin:10, width:"90%"}} autoCorrect={false} autoCapitalize={false} autoFocus placeholder="Email"></Input>
                    <Input
                    onChangeText={(text)=>{
                        this.setState({password: text})
                    }}
                    style={{margin:10}} secureTextEntry placeholder="Password"></Input>
                    <Button
                        onPress={()=>{
                            this.setState({isLoading:true})
                            firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password).then((user) =>{
                                if(user){
                                    this.props.navigation.goBack()
                                    this.props.navigation.navigate("MatchList")
                                    
                                }
                            }).catch((err)=>{
                                console.log(err)
                                this.setState({hasErrors:true, error: err.message,isLoading:false})
                            })
                        }}
                    style={{margin:10}}>
                        <Text>Login</Text>
                    </Button>
                    </View>
                    

                    <Text>Don't have an account? Please contact your Division Coordinator.</Text>
                    {this.state.hasErrors ? 
                    <Text style={{color:"red"}}>{this.state.error}</Text>  :
                    null  
                }
                </Card>
                {
                    this.state.isLoading ? 
                    <ActivityIndicator size="large" color="#ff7800" style={{position:"absolute", top:"50%",bottom:"50%", left:"50%",right:"50%"}} animating={this.state.isLoading}/>
                    : null
                }
            </View>
            </NativeBaseProvider>
        )
    }
}