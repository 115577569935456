import React from 'react';
import {
    FlatList,
    Image,
    SafeAreaView,
    ScrollView,
    StatusBar,
    StyleSheet,
    Text,
    useColorScheme,
    View,
    //Button,
    Linking
} from 'react-native';
import { AddIcon, IconButton, NativeBaseProvider, Modal, FormControl, Input, Select } from 'native-base';
import MatchItem from './components/matchitem';
import firebase from 'firebase';
import { Card, Button } from 'react-native-paper';
import axios from 'axios';

// const tempData = [{
//     matchName:"My Test Stream",
//     //matchDescription:"Jack's first test of the stream",
//     matchDate: new Date().toISOString(),
//     streamKey: "k8xm-thmt-1usc-e1km-6a3b",
//     streamURL: "rtmp://x.rtmp.youtube.com/live2/",
//     publicStreamURL: "", //when i can get from the api..
// }]

class TeamAdmin extends React.Component {

    state = {
        matchList: [],
        isAdmin: false,
        teamList: [],
        rows: [],
        newPlayers: [],
        selectedTeam: {
            players: [],
            teamStreamKey: "",
            teamDivision: "",
            teamScoreboardURL: "",
            teamName: ""
        },
        divisionList: {},
        newTeamDivision: "",
        seasonName: ""
    }

    trimScoreboardURL = (teamName = "", seasonName = "") => {
        let parsedTeamName = ""
        let parsedSeasonName = ""
        for (const letter of teamName.trim()) {
            switch (letter) {
                case " ":
                    parsedTeamName += "-"
                    break;
                case "'":
                    break;

                default:
                    parsedTeamName += letter
                    break;
            }
        }
        for (const letter1 of seasonName.trim()) {
            switch (letter1) {
                case " ":
                    parsedSeasonName += "-"
                    break;
                case "'":
                    break;

                default:
                    parsedSeasonName += letter1
                    break;
            }
        }
        return parsedSeasonName + "_" + parsedTeamName
    }

    componentDidMount() {
        // Get request for current matches
        //then set to state.
        firebase.database()
            .ref("seasons").child(this.props.route.params["seasonKey"]).child("seasonName").once("value", (season) => {
                this.setState({ seasonName: season.val() })
            })
        firebase.database()
            .ref("seasons").child(this.props.route.params["seasonKey"]).child("teams").on("value", (data) => {
                console.log(data.val())

                this.setState({
                    teamList: data.val() == null ? [] : data.val(),
                })
            })
        firebase.database()
            .ref("seasons").child(this.props.route.params["seasonKey"]).child("divisions").on("value", (data) => {
                console.log(data.val())

                this.setState({
                    divisionList: data.val() == null ? [] : data.val(),
                })
            })

        // this.setState({matchList: tempData})
    }

    render() {
        return (
            <NativeBaseProvider>
                <View style={{ width: "100%", height: "100%" }}>


                    <ScrollView style={{ width: "100%", height: "100%", backgroundColor: "#ff7800" }}>

                        {
                            Object.keys(this.state.teamList).map((key, index) => {
                                console.log(key)
                                console.log(this.state.teamList)
                                let team = this.state.teamList[key]
                                return (
                                    <View style={{ padding: 5 }} key={key}>
                                        <Card
                                            onPress={() => {
                                                this.setState({ selectedTeamKey: key, selectedTeam: this.state.teamList[key], showEditTeam: true })
                                            }}
                                            style={{ padding: 5, flexDirection: "column" }} >
                                            <Text style={{ fontSize: 24, padding: 5, fontWeight: "bold" }}>{this.state.teamList[key].teamName}</Text>
                                            <Text>Players: {team["players"].map((player) => {
                                                return player.name
                                            }).join(", ")}</Text>
                                            <Text>Division: {team.teamDivision}</Text>

                                            <Card.Actions>
                                                <Button><Text></Text></Button>
                                            </Card.Actions>
                                        </Card>
                                    </View>

                                )
                            })
                        }

                    </ScrollView>
                    <IconButton
                        onPress={() => {
                            this.setState({ showNewTeam: true })
                        }}
                        borderRadius={100} color={"white"} justifyContent={"center"} alignItems={"center"} position={"absolute"} bottom={5} right={5} bg="red.500" width={"1/5"} maxWidth={60} icon={<AddIcon   ></AddIcon>}></IconButton>
                    <Modal isOpen={this.state.showEditTeam} onClose={() => {
                        console.log("pressed")
                        this.setState({ showEditTeam: false })
                    }}>
                        <Modal.Content>
                            <Modal.CloseButton></Modal.CloseButton>
                            <Modal.Body>
                                <FormControl>
                                    <FormControl.Label>Team Name:</FormControl.Label>
                                    <Input isDisabled={true}
                                        onChangeText={(text) => {
                                            this.state.selectedTeam["teamName"] = text
                                            this.setState({ selectedTeam: this.state.selectedTeam })
                                        }}
                                        value={this.state.selectedTeam.teamName}
                                        placeholder='Team Name'></Input>
                                </FormControl>
                                <FormControl>
                                    <FormControl.Label>Team's Division</FormControl.Label>
                                    <Select
                                        selectedValue={this.state.selectedTeam["teamDivision"]}
                                        onValueChange={(val) => {
                                            console.log(val)
                                            //console.log(this.state)
                                            this.state.selectedTeam.teamDivision = val
                                            this.setState({ selectedTeam: this.state.selectedTeam }, () => {
                                                console.log(this.state)
                                            })
                                        }}
                                    >
                                        {
                                            Object.keys(this.state.divisionList).map((division) => {
                                                let divisionInfo = this.state.divisionList[division]
                                                console.log(divisionInfo)
                                                return (
                                                    <Select.Item label={divisionInfo["divisionName"]} key={division} value={divisionInfo["divisionName"]}></Select.Item>
                                                )
                                            })
                                        }

                                    </Select>
                                </FormControl>

                                {/* {<FormControl>
                                    <FormControl.Label>Stream Key(Jack will add later):</FormControl.Label>
                                    <Input
                                        onChangeText={(text) => {
                                            this.state.selectedTeam["teamStreamKey"] = text
                                            this.setState({ selectedTeam: this.state.selectedTeam })
                                        }}
                                        value={this.state.selectedTeam["teamStreamKey"]}
                                        placeholder='Stream Key'></Input>
                                </FormControl>
                                <FormControl>
                                    <FormControl.Label>Scoreboard URL(Jack will add later):</FormControl.Label>
                                    <Input
                                        onChangeText={(text) => {
                                            this.state.selectedTeam["teamScoreboardURL"] = text
                                            this.setState({ selectedTeam: this.state.selectedTeam })
                                        }}
                                        value={this.state.selectedTeam["teamScoreboardURL"]}
                                        placeholder='Scoreboard URL'></Input>
                                </FormControl>} */}
                                {
                                    this.state.selectedTeam.players.map((player, index) => {
                                        return (
                                            <FormControl>
                                                <FormControl>Player Name:</FormControl>
                                                <Input value={player["name"]}
                                                    onChangeText={(text) => {
                                                        this.state.selectedTeam.players[index] = { name: text }
                                                        this.setState({ selectedTeam: this.state.selectedTeam })
                                                    }}
                                                ></Input>
                                            </FormControl>
                                        )
                                    })

                                }
                                <Button onPress={() => {
                                    this.state.selectedTeam.players.push({ name: "" })
                                    this.setState({ selectedTeam: this.state.selectedTeam })
                                }}  >
                                    <Text>Add Player</Text>
                                </Button>

                            </Modal.Body>
                            <Modal.Footer>
                                <Button onPress={() => {
                                    firebase.database()
                                        .ref("seasons").child(this.props.route.params["seasonKey"]).child("teams").child(this.state.selectedTeamKey).update(this.state.selectedTeam)

                                    this.setState({
                                        selectedTeam: {
                                            players: []
                                        }, showEditTeam: false, selectedTeamKey: 0, selectedTeamName: ""
                                    })
                                }}  >
                                    <Text>Save</Text>
                                </Button>
                            </Modal.Footer>
                        </Modal.Content>

                    </Modal>


                    <Modal isOpen={this.state.showNewTeam} onClose={() => {
                        console.log("pressed")
                        this.setState({ showNewTeam: false })
                    }}>
                        <Modal.Content>
                            <Modal.CloseButton
                            ></Modal.CloseButton>
                            <Modal.Header><Text>Add New Team</Text></Modal.Header>
                            <Modal.Body>
                                <FormControl>
                                    <FormControl.Label>Team Name:</FormControl.Label>
                                    <Input
                                        onChangeText={(text) => {
                                            this.setState({ newTeamName: text })
                                        }}
                                        value={this.state.newTeamName}
                                        placeholder='Team Name'></Input>
                                </FormControl>

                                <FormControl>
                                    <FormControl.Label>Team's Division</FormControl.Label>
                                    <Select
                                        defaultValue=''
                                        selectedValue={this.state.newTeamDivision}
                                        onValueChange={(val) => {
                                            console.log(val)
                                            //console.log(this.state)
                                            this.setState({ newTeamDivision: val }, () => {
                                                console.log(this.state)
                                            })
                                        }}
                                    >
                                        <Select.Item label='Select Division..' value=''></Select.Item>
                                        {
                                            Object.keys(this.state.divisionList).map((division) => {
                                                let divisionInfo = this.state.divisionList[division]
                                                console.log(divisionInfo)
                                                return (
                                                    <Select.Item label={divisionInfo["divisionName"]} key={division} value={divisionInfo["divisionName"]}></Select.Item>
                                                )
                                            })
                                        }

                                    </Select>
                                </FormControl>
                                {/* {<FormControl>
                                    <FormControl.Label>Stream Key(Jack will add later):</FormControl.Label>
                                    <Input
                                        onChangeText={(text) => {
                                            this.setState({ newTeamStreamKey: text })
                                        }}
                                        value={this.state.newTeamStreamKey}
                                        placeholder='Stream Key'></Input>
                                </FormControl>
                                <FormControl>
                                    <FormControl.Label>Scoreboard URL(Jack will add later):</FormControl.Label>
                                    <Input
                                        onChangeText={(text) => {
                                            this.setState({ newTeamScoreboardURL: text })
                                        }}
                                        value={this.state.newTeamScoreboardURL}
                                        placeholder='Scoreboard URL'></Input>
                                </FormControl>} */}
                                {
                                    this.state.newPlayers.map((player, index) => {
                                        return (
                                            <FormControl>
                                                <FormControl>Player Name:</FormControl>
                                                <Input
                                                    onChangeText={(text) => {
                                                        this.state.newPlayers[index] = { name: text }
                                                        this.setState({ newPlayers: this.state.newPlayers })
                                                    }}
                                                ></Input>
                                            </FormControl>
                                        )
                                    })
                                }
                                <Button onPress={() => {
                                    this.setState({ newPlayers: [...this.state.newPlayers, { name: "" }] })
                                }}  >
                                    <Text>Add Player</Text>
                                </Button>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    disabled={this.state.newPlayers.length < 2 || this.state.newTeamDivision == ""}
                                    onPress={async () => {

                                        axios.post("https://live.lapingpong.com:5443/LAPingPong/rest/v2/broadcasts/create", {
                                            "streamId": this.trimScoreboardURL(this.state.newTeamName, this.state.seasonName),
                                            "type": "liveStream",
                                            "name": this.state.seasonName + " "+this.state.newTeamName,
                                            "description": "",
                                            "publish": true,
                                            "publicStream": true,
                                            "is360": false,
                                            "mp4Enabled": 1
                                        })
                                        firebase.database()
                                            .ref("seasons")
                                            .child(this.props.route.params["seasonKey"])
                                            .child("teams")
                                            .push({
                                                teamName: this.state.newTeamName,
                                                players: this.state.newPlayers,
                                                teamDivision: this.state.newTeamDivision,
                                                teamStreamKey: this.trimScoreboardURL(this.state.newTeamName, this.state.seasonName),
                                                teamScoreboardURL: this.trimScoreboardURL(this.state.newTeamName, this.state.seasonName),
                                                scoreboardPath: this.trimScoreboardURL(this.state.newTeamName, this.state.seasonName)
                                            })
                                        firebase.database().ref("scoreboards").child(this.trimScoreboardURL(this.state.newTeamName, this.state.seasonName)).update({ teamName: this.state.newTeamName, matchId: "", })
                                        this.setState({ newPlayers: [], newTeamName: "", newTeamScoreboardURL: "", newTeamDivision: "", newTeamStreamKey: "", showNewTeam: false, scoreboardPath: this.trimScoreboardURL(this.state.newTeamName, this.state.seasonName) })

                                    }}
                                >
                                    <Text>Save</Text>
                                </Button>
                            </Modal.Footer>
                        </Modal.Content>

                    </Modal>

                </View>
            </NativeBaseProvider>

        )
    }
}
export default TeamAdmin