
/// Spent too much time, not worth it.


import React from 'react';
//import ballImage from require('../img/lapp-ball.png')
import { Image, Animated, Easing, Text} from 'react-native'
//import ballImage from '../img/lapp-ball.png'

import {LAPingPongBallLogo} from '../classes/images'
function SpinningBall(props){
    var spinValue = new Animated.Value(0);

// First set up animation 
Animated.timing(
    spinValue,
  {
    toValue: 30,
    duration: 12000,
    easing: Easing.linear, // Easing is an additional import from react-native
    useNativeDriver: true  // To make use of native driver for performance
  }
).start()

// Next, interpolate beginning and end values (in this case 0 and 1)
const spin = spinValue.interpolate({
  inputRange: [0, 1],
  outputRange: ['0deg', '360deg']
})

return (
    <Animated.Image  style={{width:props.width || 300,height: props.height || 300, transform:[{rotate:spin}], resizeMode:"contain"}} source={LAPingPongBallLogo()}
    >
    </Animated.Image>
)

   
}

export default SpinningBall


