import React from 'react';
import {
    FlatList,
    Image,
    SafeAreaView,
    ScrollView,
    StatusBar,
    StyleSheet,
    Text,
    useColorScheme,
    View,
    //Button,
    Linking
} from 'react-native';
import Team from './classes/Team';
import MatchItem from './components/matchitem';
import firebase from 'firebase';
import { Card, Button, ToggleButton } from 'react-native-paper';
import { NativeBaseProvider, Switch, Modal,IconButton, AddIcon, FormControl, Input, HStack } from 'native-base';
import axios from 'axios';

// const tempData = [{
//     matchName:"My Test Stream",
//     //matchDescription:"Jack's first test of the stream",
//     matchDate: new Date().toISOString(),
//     streamKey: "k8xm-thmt-1usc-e1km-6a3b",
//     streamURL: "rtmp://x.rtmp.youtube.com/live2/",
//     publicStreamURL: "", //when i can get from the api..
// }]

class SeasonAdmin extends React.Component {

    state={
        matchList: [],
        isAdmin: false,
        seasonList: [],
        rows:[]
    }

    componentDidMount(){
        // Get request for current matches
        //then set to state.
        firebase.database()
        .ref("seasons").orderByChild("isActive").on("value", (data) => {
            console.log(data.val())

            this.setState({seasonList:data.val(), rows: Object.keys(data.val()).map((key,index)=>{
                //
                return (
                   {...data.val()[key], id: index}
                )
            })})
        })

       // this.setState({matchList: tempData})
    }

    trimScoreboardURL(teamName = "", seasonName = "") {
        let parsedTeamName = ""
        let parsedSeasonName = ""
        for (const letter of teamName.trim()) {
            switch (letter) {
                case " ":
                    parsedTeamName += "-"
                    break;
                case "'":
                    break;

                default:
                    parsedTeamName += letter
                    break;
            }
        }
        for (const letter1 of seasonName.trim()) {
            switch (letter1) {
                case " ":
                    parsedSeasonName += "-"
                    break;
                case "'":
                    break;

                default:
                    parsedSeasonName += letter1
                    break;
            }
        }
        return parsedSeasonName + "_" + parsedTeamName
    }

    render(){
        return(
            <NativeBaseProvider>
                <ScrollView style={{width:"100%", height:"100%", backgroundColor:"#ff7800"}}>
           
           {
                        Object.keys(this.state.seasonList).map((key,index)=>{
                            console.log(key)
                            console.log(this.state.seasonList)
                            let season = this.state.seasonList[key]
                            return (
                                <View  style={{padding:5}} key={key}>
                                    <Card style={{padding:5, flexDirection:"column"}} >
                                    <Text style={{fontSize:24, padding:5, fontWeight:"bold"}}>{this.state.seasonList[key].seasonName}</Text>
                                    <Text>{season["seasonTime"]} Season: {season["seasonStartDate"]} to {season["seasonEndDate"]}</Text>
                                    <View style={{flexDirection:"row",flexWrap:"wrap"}}>
 <Button onPress={()=>{
                                        this.props.navigation.navigate("TeamAdmin", {seasonKey: key})
                                    }}>
                                        <Text>Manage Teams</Text>
                                        </Button>
                                        <Button onPress={()=>{
                                        this.props.navigation.navigate("MatchAdmin",{seasonKey: key})
                                    }}>
                                        <Text>Manage Matches</Text>
                                        </Button>
                                        <Button onPress={()=>{
                                        this.props.navigation.navigate("DivisionAdmin",{seasonKey: key})
                                    }}>
                                        <Text>Manage Divisions</Text>
                                        </Button>
                                        <Button onPress={()=>{
                                        axios.get("https://www.lapingpong.com/results/leaguedata.php").then((results) =>{
                                            console.log(results.data)
                                            let allTeams = []
                                            for (const division of results.data) {
                                                for (const team of division.teams) {
                                                    allTeams.push({...team, division:division.name})
                                                }
                                            }
                                            console.log(allTeams)
                                            firebase.database().ref("seasons").child(key).child("teams").once("value", (existingTeams)=>{
                                                console.log(existingTeams.val())
                                                var allExistingTeams = Object.keys(existingTeams.val() ? existingTeams.val() :[]).map((teamKey) =>{
                                                    console.log(teamKey)
                                                    return {...existingTeams.val()[teamKey], teamKey:teamKey}
                                                })
                                                let team = {}
                                                let teamFound = false
                                                console.log(allExistingTeams)
                                                for (const importedTeam of allTeams) {
                                                        team = {}
                                                        teamFound = false
                                                    
                                                    for (const existingTeam of allExistingTeams) {
                                                        if(existingTeam.teamName === importedTeam.name && importedTeam.division === existingTeam.teamDivision)
                                                        {
                                                            teamFound =true
                                                            team = {...existingTeam}
                                                        }
                                                        console.log(importedTeam)
                                                        console.log(existingTeam)
                                                    }
                                                    if(!teamFound){
                                                        console.log("Add this team")
                                                        console.log(importedTeam)

                                                        firebase.database().ref("seasons").child(key).child("teams").push( new Team(importedTeam.name,importedTeam.division, importedTeam.players, this.state.seasonList[key].seasonName ))
                                                        firebase.database().ref("scoreboards").child(this.trimScoreboardURL(importedTeam.name, this.state.seasonList[key].seasonName)).update({ teamName: importedTeam.name, matchId: "", })
                                                    }
                                                    else{
                                                        let newPlayerList = []
                                                        for (const player of importedTeam.players) {
                                                            newPlayerList.push({name: player.firstName})
                                                        }
                                                        firebase.database().ref("seasons").child(key).child("teams").child(team["teamKey"]).child("players").set(newPlayerList)
                                                    }

                                                }

                                                
                                            })

                                        })
                                    }}>
                                        <Text>Sync Teams</Text>
                                        </Button>
                                    </View>
                                    <HStack>
                                      <Text>Season is Active:  </Text><Switch
                                  onValueChange={(toggle)=>{
                                    firebase.database()
                                    .ref("seasons").child(key).update({isActive: toggle})
                                    this.state.seasonList[key].isActive = toggle
                                    this.setState({seasonList:this.state.seasonList})
                                  }}
                                  value={this.state.seasonList[key].isActive}></Switch>  
                                    </HStack>
                                  
                                </Card>
                                </View>
                                
                            )
                        }).sort((a, b)=> {
                            console.log(new Date(this.state.seasonList[a.key].seasonStartDate) )
                            console.log(new Date(this.state.seasonList[b.key].seasonStartDate))
                            if(new Date(this.state.seasonList[a.key].seasonStartDate) > new Date(this.state.seasonList[b.key].seasonStartDate) ){
                                return -1
                            }
                            })
                    }
            
        </ScrollView>
        <IconButton
                    onPress={() => {
                        this.setState({ showNewSeason: true })
                    }}
                    borderRadius={100} color={"white"} justifyContent={"center"} alignItems={"center"} position={"absolute"} bottom={5} right={5} bg="red.500" width={"1/5"} maxWidth={60} icon={<AddIcon   ></AddIcon>}>

                    </IconButton>

                    <Modal isOpen={this.state.showNewSeason} onClose={() => {
                        console.log("pressed")
                        this.setState({ showNewSeason: false })
                    }}>
                        <Modal.Content>
                            <Modal.CloseButton
                            ></Modal.CloseButton>
                            <Modal.Header><Text>Add New Season</Text></Modal.Header>
                            <Modal.Body>
                                <FormControl>
                                    <FormControl.Label>Season Name:</FormControl.Label>
                                    <Input
                                        onChangeText={(text) => {
                                            this.setState({ newSeasonName: text })
                                        }}
                                        value={this.state.newSeasonName}
                                        placeholder='Season Name'></Input>
                                </FormControl>
                                
                               
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    disabled={ this.state.newSeasonName == ""}
                                    onPress={() => {
                                        firebase.database()
                                            .ref("seasons").push({seasonName:this.state.newSeasonName, isActive:0})
                                        this.setState({ newSeasonName: "", showNewDivision: false })
                                    }}
                                >
                                    <Text>Save</Text>
                                </Button>
                            </Modal.Footer>
                        </Modal.Content>

                    </Modal>
            </NativeBaseProvider>
        
            
        )
    }
}
export default SeasonAdmin