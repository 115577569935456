/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow strict-local
 */

 import React from 'react';
 //import SpinningBall from './components/spinningloadingball'
 
 import {
   SafeAreaView,
   ScrollView,
   StatusBar,
   StyleSheet,
   Text,
   useColorScheme,
   View, Button, TouchableOpacity
 } from 'react-native';
 
 import firebase from 'firebase'

var firebaseConfig = {
    apiKey: "AIzaSyACbxFrMGwJayyA1s2emn_UBItyjtLqgG0",
    authDomain: "la-ping-pong-stream.firebaseapp.com",
    projectId: "la-ping-pong-stream",
    storageBucket: "la-ping-pong-stream.appspot.com",
    messagingSenderId: "480766358304",
    appId: "1:480766358304:web:1c48cb68bf88ad98415d45",
    measurementId: "G-XFD2DB0DTB"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

 
 // import {
 //   Colors,
 //   DebugInstructions,
 //   Header,
 //   LearnMoreLinks,
 //   ReloadInstructions,
 // } from 'react-native/Libraries/NewAppScreen';
 import LoadingScreen from './src/login/loadingscreen';
 import { NavigationContainer } from '@react-navigation/native';
 import { createStackNavigator } from '@react-navigation/stack';
 // import { useEffect } from 'react';
 // import axios from 'axios';
 // import { InitialLoadURL } from './src/settings';
 //import { useState } from 'react';
 import LatestPosts from './src/latestposts';
 import SeasonAdmin from './src/SeasonAdmin';
 import DivisionAdmin from './src/DivisionAdmin';
 import MatchAdmin from './src/MatchesAdmin';
 // import { createDrawerNavigator } from '@react-navigation/drawer';
 // import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
 
 import ChooseMatchList from './src/choosematch';
 //import StreamCamera from './src/cameraStream';
 import ScheduledMatches from './src/scheduledmatches'
 // import firebase from 'firebase';
 // import { Icon } from 'react-native-paper';
 import LoginScreen from './src/login/loginscreen';
 
 import StreamInstructions from './src/streamdirections'
 import KeepLeagueScore from './src/components/keepleaguescore'
import TeamAdmin from './src/TeamAdmin';
 class App extends React.Component{
 
   state = {
     initialLoadDone: true,
     initialData: null
   }
 
   componentDidMount(){
 
     // firebase.signInAnonymously().then((user)=>{
     //   console.log(user)
     //   this.setState({
     //              initialLoadDone: true,
     //     //         initialData: data.data
     //           })
     // }).catch((err)=>{
     //   console.log(err)
     //   this.setState({
     //             initialLoadDone: true,
     //     //         initialData: data.data
     //            })
     // })
 
 
     // axios.get(InitialLoadURL).then((data) =>{ // change to post if need be
     //       console.log(InitialLoadURL)
     //       console.log(data.data)
     //       this.setState({
     //         initialLoadDone: true,
     //         initialData: data.data
     //       })
     //     })
   }
 
   // MyTabBar = ({ navigation }) => {
   //   return (
   //     <View style={{flexDirection:"row",minHeight:50}}>
   //       <TouchableOpacity onPress={() => {
   //         // Navigate using the `navigation` prop that you received
   //         navigation.navigate('LatestNews');
   //       }} style={{flexDirection:"column", width:"50%"}}>
   //         <Text>Latest News</Text>
   //       <FontAwesome  />
   //       </TouchableOpacity>
   //       <TouchableOpacity onPress={() => {
   //         // Navigate using the `navigation` prop that you received
   //         navigation.navigate('MatchList');
   //       }} style={{flexDirection:"column",width:"50%"}}>
   //         <Text>Live Stream</Text>
   //        <Text >{"\uf03d"}</Text>
   //       </TouchableOpacity>
   //     </View>
       
   //   );
   // }
 //
   NewsArticlesStack = ({navigation}) => {
     const StackNav = createStackNavigator()
     //const StackNav = createBottomTabNavigator()
     return (
       <StackNav.Navigator  initialRouteName="LatestNews" screenOptions={{headerShown:true, title:"LA Ping Pong"}}>
         <StackNav.Screen name="LatestNews" component={LatestPosts} options={{headerStyle:{backgroundColor:"#ff7800"}, title:"LA Ping Pong Latest News"}}  />
           <StackNav.Screen name="MatchList" component={ChooseMatchList}  options={({navigation}) => ({headerStyle:{backgroundColor:"#ff7800"}, title:"Select Your Match", 
           // headerRight: ({navigation}) => { return(
           //   <Button
           //     onPress={() => {
           //       navigation.goBack()
           //       firebase.signOut()}}
           //     title="Sign Out"
           //     color="#fff"
           //   />)}<StackNav.Screen name="StreamMatch" component={StreamCamera}  options={{ headerTransparent:false, }} />
           })} 
           />
         <StackNav.Screen name="ScheduledMatches" component={ScheduledMatches} options={{headerStyle:{backgroundColor:"#ff7800"}, title:"Scheduled Matches"}} />
           
            <StackNav.Screen name="LoginScreen" component={LoginScreen} options={{headerStyle:{backgroundColor:"#ff7800"}, title:"Login"}} />
            <StackNav.Screen name="KeepLeagueScore" component={KeepLeagueScore} options={{headerStyle:{backgroundColor:"#ff7800", }, title:"Keep Score" }} />
            <StackNav.Screen name="StreamInstructions" component={StreamInstructions} options={{headerStyle:{backgroundColor:"#ff7800"},title:"Stream Instructions" }} />
            <StackNav.Screen name="KeepScore" component={KeepLeagueScore} options={{headerStyle:{backgroundColor:"#ff7800"},title:"Keep Score" }}  />
            <StackNav.Screen name="SeasonAdmin" component={SeasonAdmin} options={{headerStyle:{backgroundColor:"#ff7800"},title:"Manage Seasons" }}  />
            <StackNav.Screen name="DivisionAdmin" component={DivisionAdmin} options={{headerStyle:{backgroundColor:"#ff7800"},title:"Manage Divisions" }}  />
            <StackNav.Screen name="TeamAdmin" component={TeamAdmin} options={{headerStyle:{backgroundColor:"#ff7800"},title:"Manage Teams", title:"Manage Teams" }}  />
            <StackNav.Screen name="MatchAdmin" component={MatchAdmin} options={{headerStyle:{backgroundColor:"#ff7800"},title:"Manage Matches", title:"Manage Matches" }} />

          </StackNav.Navigator>
     )
   }
 
   render(){
 
 
     
     //const DrawerNav = createDrawerNavigator()
     
     if(this.state.initialLoadDone == true){
     /**
      * <DrawerNav.Navigator>
            <DrawerNav.Screen name="Latest News" component={this.NewsArticlesStack} />
       
       
     </DrawerNav.Navigator>
      */
       return (
        <NavigationContainer >
          <this.NewsArticlesStack />
     </NavigationContainer>
     )
    
   }
   else {
     return <LoadingScreen></LoadingScreen>
   }
     
   }
 
   
 
   // let [initialLoadDone, setInitialLoadDone] =  useState(false)
   // let [initialData, setInitialData] = useState(null)
   
   // useEffect(()=>{
   //   axios.get(InitialLoadURL).then((data) =>{
   //     console.log(InitialLoadURL)
   //     console.log(data.data)
   //     setInitialData(data.data)
   //     setInitialLoadDone(true)
   //   })
   // })
 
   
 
 };
 
 
 
 export default App;
 