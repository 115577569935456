import React, { Component } from 'react';
import { Dimensions, View, ScrollView} from 'react-native';
import firebase from 'firebase'
import { 
     Button, Text, 
     NativeBaseProvider, Actionsheet, } from 'native-base';
//import CountDown from '../countDown'
import { Dialog, } from 'react-native-paper';
import Game from '../classes/Game'
//import { width } from 'styled-system';
//import { tournamentDB } from '../config';
//import Stats from '../classes/Statistics'
////import LiveStats from '../classes/LiveStats'
import Icon from 'react-native-vector-icons/FontAwesome5';


var tournamentDB = "matches"

export default class KeepLeagueScore extends Component {

    constructor(props) {
        super(props)

        this.timerIsDone = this.timerIsDone.bind(this)
    }

    static navigationOptions = ({ navigation }) => {
        return {
            title: "Keep Score",
        }
    }

    endATimeOut() {
        firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("tables").child("0").update({ ATimeOutActive: false, AHasTimeOut: false })
        this.setState({ ATimeOutActive: false, AHasTimeOut: false })
    }

    endBTimeOut() {
        firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("tables").child("0").update({ BTimeOutActive: false, BHasTimeOut: false })
        this.setState({ BTimeOutActive: false, BHasTimeOut: false })
    }

    timerIsDone(AorB) {
        AorB == "A" ? this.endATimeOut() : null
        AorB == "B" ? this.endBTimeOut() : null

    }

    setHostTeamFirstName(playerName) {
        this.onFieldChange("PlayerAFirstName", playerName)
        //this.setState({PlayerAFirstName: playerName})
    }
    setVisitorTeamFirstName(playerName) {
        this.onFieldChange("PlayerBFirstName", playerName)
        // this.setState({PlayerBFirstName:playerName})
    }



    checkingTheServe(AScore, BScore, totalGames) {
        var scoreTotal = AScore + BScore;
        //console.log("Ascore", AScore)
        //console.log("BScore", BScore)


        if (totalGames == 0 || totalGames == 2 || totalGames == 4 || totalGames == 6) {
            if (this.state.AServes) {
                if (scoreTotal < 20) {
                    var AwillServePoints = [0, 1, 4, 5, 8, 9, 12, 13, 16, 17]
                    if (AwillServePoints.includes(scoreTotal)) {
                        this.setState({ AisServing: true, validateService: false, })
                        firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("tables").child("0").update({ AisServing: true })

                    }

                    else {
                        this.setState({ AisServing: false, validateService: false, })
                        firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("tables").child("0").update({ AisServing: false })

                    }

                }
                else if (scoreTotal >= 20) {

                    if (scoreTotal == 20) {
                        this.setState({ AisServing: true, validateService: false, })
                        firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("tables").child("0").update({ AisServing: true })

                    }
                    else {
                        if (this.state.AisServing) {
                            this.setState({ AisServing: false, validateService: false, })
                            firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("tables").child("0").update({ AisServing: false })

                        } else {
                            this.setState({ AisServing: true, validateService: false, })
                            firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("tables").child("0").update({ AisServing: true })

                        }

                    }
                }
            }
            else if (!this.state.AServes) {
                if (scoreTotal < 20) {
                    var BwillServePoints = [0, 1, 4, 5, 8, 9, 12, 13, 16, 17]
                    if (BwillServePoints.includes(scoreTotal)) {
                        this.setState({ AisServing: false, validateService: false, })
                        firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("tables").child("0").update({ AisServing: false })

                    } else {
                        this.setState({ AisServing: true, validateService: false, })
                        firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("tables").child("0").update({ AisServing: true })

                    }
                }
                else if (scoreTotal >= 20) {
                    if (scoreTotal == 20) {
                        this.setState({ AisServing: false, validateService: false })
                        firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("tables").child("0").update({ AisServing: false })

                    }
                    else {
                        if (this.state.AisServing) {
                            this.setState({ AisServing: false, validateService: false })
                            firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("tables").child("0").update({ AisServing: false })

                        }
                        else {
                            this.setState({ AisServing: true, validateService: false, })
                            firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("tables").child("0").update({ AisServing: true })

                        }
                    }

                }
            }

        }
        else if (totalGames == 1 || totalGames == 3 || totalGames == 5) {
            if (!this.state.AServes) {
                if (scoreTotal < 20) {
                    var AwillServePoints = [0, 1, 4, 5, 8, 9, 12, 13, 16, 17]
                    if (AwillServePoints.includes(scoreTotal)) {
                        this.setState({ AisServing: true, validateService: false, })
                        firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("tables").child("0").update({ AisServing: true })

                    }
                    else {
                        this.setState({ AisServing: false, validateService: false, })
                        firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("tables").child("0").update({ AisServing: false })

                    }

                }
                else if (scoreTotal >= 20) {
                    if (scoreTotal == 20) {
                        this.setState({ AisServing: true, validateService: false, })
                        firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("tables").child("0").update({ AisServing: true })

                    }
                    else {
                        if (this.state.AisServing) {
                            this.setState({ AisServing: false, validateService: false, })
                            firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("tables").child("0").update({ AisServing: false })

                        } else {
                            this.setState({ AisServing: true, validateService: false, })
                            firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("tables").child("0").update({ AisServing: true })

                        }

                    }
                }
            }
            else if (this.state.AServes) {
                if (scoreTotal < 20) {
                    var BwillServePoints = [0, 1, 4, 5, 8, 9, 12, 13, 16, 17]
                    if (BwillServePoints.includes(scoreTotal)) {
                        this.setState({ AisServing: false, validateService: false, })
                        firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("tables").child("0").update({ AisServing: false })

                    }
                    else {
                        this.setState({ AisServing: true, validateService: false, })
                        firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("tables").child("0").update({ AisServing: true })

                    }

                }
                else if (scoreTotal >= 20) {
                    if (scoreTotal == 20) {
                        this.setState({ AisServing: false, validateService: false, })
                        firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("tables").child("0").update({ AisServing: false })

                    }
                    else {
                        if (this.state.AisServing) {
                            this.setState({ AisServing: false, validateService: false })
                            firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("tables").child("0").update({ AisServing: false })


                        }
                        else {
                            this.setState({ AisServing: true, validateService: false, })
                            firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("tables").child("0").update({ AisServing: true })

                        }

                    }

                }
            }
        }
    }


    timeOutTimer() {

        this.setState({ timeOutTime: this.state.timeOutTime - 1 })

    }

    timeOut(side) {
        if (side == "left") {
            if (this.state.switch) {
                this.setState({ showBTimeOutDialog: true })
            }
            else {
                this.setState({ showATimeOutDialog: true })

            }
        }
        if (side == "right") {
            if (this.state.switch) {
                this.setState({ showATimeOutDialog: true })
            }
            else {
                this.setState({ showBTimeOutDialog: true })
            }
        }
    }


    validateMatchScore(numOfGames, AMatch, BMatch) {
        if (numOfGames == 3) {
            if (AMatch == 2) {
                this.setState({ showAWonMatchDialog: true, validateMatchScores: false })
                firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("TeamAWins").once("value", (data) => {
                    var gamesWon_int = parseInt(data.val())
                    firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).update({ TeamAWins: gamesWon_int + 1 })
                })
            }
            else if (BMatch == 2) {
                this.setState({ showBWonMatchDialog: true, validateMatchScores: false })
                firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("TeamBWins").once("value", (data) => {
                    var gamesWon_int = parseInt(data.val())
                    firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).update({ TeamBWins: gamesWon_int + 1 })
                })
            }

        }
        else if (numOfGames == 5) {
            if (AMatch == 3) {
                this.setState({ showAWonMatchDialog: true, validateMatchScores: false })
                firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("TeamAWins").once("value", (data) => {
                    var gamesWon_int = parseInt(data.val())
                    firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).update({ TeamAWins: gamesWon_int + 1 })
                })
            }
            else if (BMatch == 3) {
                this.setState({ showBWonMatchDialog: true, validateMatchScores: false })
                firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("TeamBWins").once("value", (data) => {
                    var gamesWon_int = parseInt(data.val())
                    firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).update({ TeamBWins: gamesWon_int + 1 })
                })

            }

        }
        else if (numOfGames == 7) {
            if (AMatch == 4) {
                this.setState({ showAWonMatchDialog: true, validateMatchScores: false })
                firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("TeamAWins").once("value", (data) => {
                    var gamesWon_int = parseInt(data.val())
                    firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).update({ TeamAWins: gamesWon_int + 1 })
                })
            }
            else if (BMatch == 4) {
                this.setState({ showBWonMatchDialog: true, validateMatchScores: false })
                firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("TeamBWins").once("value", (data) => {
                    var gamesWon_int = parseInt(data.val())
                    firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).update({ TeamBWins: gamesWon_int + 1 })
                })
            }
        }
    }


    validateScores(AScore, BScore) {


        if (!this.state.showAWonGameDialog || !this.state.showBWonGameDialog) {
            if (AScore >= 10 && BScore >= 10) {
                var pointDifference = Math.abs(AScore - BScore)
                if (pointDifference == 2) {
                    if (AScore > BScore) {
                        this.setState({ showAWonGameDialog: true, validateScores: false })
                    }
                    else if (AScore < BScore) {
                        this.setState({ showBWonGameDialog: true, validateScores: false })
                    }
                }
            }
            else {
                if (AScore == 11 && BScore < 10) {
                    AScore > 11 ? this.onFieldChange("playerAScore", 11) : null
                    this.setState({ showAWonGameDialog: true, validateScores: false })

                }
                else if (BScore == 11 && AScore < 10) {
                    BScore > 11 ? this.onFieldChange("playerBScore", 11) : null
                    this.setState({ showBWonGameDialog: true, validateScores: false })
                }
            }
        }
        else {
            null
        }

    }

    state = {
        /*
        ColorA: ""
  ColorB: ""
  CountryA: ""
  CountryB: ""
  PlayerA2FirstName: ""
  PlayerA2LastName: ""
  PlayerAFirstName: ""
  PlayerALastName: ""
  PlayerB2FirstName: ""
  PlayerB2LastName: ""
  PlayerBFirstName: ""
  PlayerBLastName: ""
  TeamAName: ""
  TeamBName: ""
  isDoubles: false
  isInternationalTournament: true
  isTeamTournament: true
  playerAMatchScore: 0
  playerAScore: 0
  playerBMatchScore: 0
  playerBScore: 0
  showFlags: true
  status: "inactive"
  switch: false
  tournamentName: ""
        */
       readyToKeepScore:false,
        active: false,
        timeOutDialog: true,
        showAWonGameDialog: false,
        showBWonGameDialog: false,
        showATimeOutDialog: false,
        showBTimeOutDialog: false,
        validateScores: true,
        validateMatchScores: true,
        showAWonMatchDialog: false,
        showBWonMatchDialog: false,
        showNewGameDialog: false,
        showServiceChoice: false,
        validateService: true,
        hostingTeamPlayers: [],
        visitingTeamPlayers: [],
        TeamANameLeague:"",
        TeamBNameLeague:""

        //token: this.props.navigation.getParam("token")


    }





    UNSAFE_componentWillMount() {
        console.log(this.props)
        //console.log(firebase.auth().currentUser.displayName)
        firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("season")
        firebase.database().ref("seasons").child(this.props.route.params.seasonid).child("teams").once("value", (teams)=>{
            console.log(teams.val())
            this.setState({teamList: teams.val()}, ()=>{
                firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("TeamAName").once("value", (wins) => {
                    if (typeof wins.val() != "undefined") {
                        console.log(wins.val())
                    
                        this.setState({ TeamANameLeague: this.state.teamList[wins.val()].teamName }, ()=>{
                            this.props.navigation.setOptions({ title: this.state.TeamANameLeague + " VS " + this.state.TeamBNameLeague })

                        })
                    }
                })
                firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("TeamBName").once("value", (wins) => {
                    if (typeof wins.val() != "undefined") {
                        this.setState({ TeamBNameLeague: this.state.teamList[wins.val()].teamName }, ()=>{
                            this.props.navigation.setOptions({ title: this.state.TeamANameLeague + " VS " + this.state.TeamBNameLeague })

                        })
                    }
                })
            })
        })

        firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("tables").child("0").once("value", (val) => {
            //console.log("Firebase updated state")
            this.setState(val.val())
            //console.log("This is the game settings")
            // this.setState((state, props) => {return {firstLoad: false}})


        })
        var FieldsThatNeedToBeUpdatedRegularly = ["PlayerAScore","PlayerBScore","PlayerAMatchScore", "PlayerBMatchScore", "AScores","BScores", "AServes"] //Just Add more if they need to be regularly updated.

        for (const field of FieldsThatNeedToBeUpdatedRegularly) {
            firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("tables").child("0").child(field).on("value",(fieldReturned)=>{
            this.setState({[field]:fieldReturned.val()})
        })
    }
        /*firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("TournamentName").on("value", (val) => {
            this.setState({ TournamentName: val.val() })
        })
        */
        // firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("TeamAName").once("value", (wins) => {
        //     if (typeof wins.val() != "undefined") {
        //         console.log(wins.val())
            
        //         this.setState({ TeamANameLeague: wins.val() })
        //     }
        // })
        // firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("TeamBName").once("value", (wins) => {
        //     if (typeof wins.val() != "undefined") {
        //         this.setState({ TeamBNameLeague: wins.val() })
        //     }
        // })
        // Loading the Host and Vistor Team Players
        console.log(this.props.route.params.seasonid, this.props.route.params.divisionid)


        firebase.database().ref("seasons").child(this.props.route.params.seasonid).child("teams").once("value", (divisionData) => {
            console.log(divisionData.val())
            if(divisionData.val()[this.props.route.params.visitorTeamID]["players"] && divisionData.val()[this.props.route.params.hostTeamID]["players"] ) {
                var visitingPlayerNames = Object.keys(divisionData.val()[this.props.route.params.visitorTeamID]["players"]).map((playerKey) => {
                return divisionData.val()[this.props.route.params.visitorTeamID]["players"][playerKey]["name"]
            })
            var hostingPlayerNames = Object.keys(divisionData.val()[this.props.route.params.hostTeamID]["players"]).map((playerKey) => {
                return divisionData.val()[this.props.route.params.hostTeamID]["players"][playerKey]["name"]
            })
            this.setState({ hostingTeamPlayers: hostingPlayerNames, visitingTeamPlayers: visitingPlayerNames, 
                //TeamANameLeague: divisionData.val()[this.props.route.params.hostTeamID].teamName, TeamBNameLeague:divisionData.val()[this.props.route.params.visitorTeamID].teamName
             })
            }
            else{
                console.log("no players")
                this.setState({noPlayers:true})
                //this.props.navigation.goBack()
            }
            

        })

    }

    componentDidUpdate() {
        // firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("tables").child("0").set(this.state)
        //console.log("i updated")
        //console.log(this.state.validateService, this.state.validateScores, this.state.validateMatchScores, )
        //this.state.validateService ? this.serviceSide(this.state.playerAScore, this.state.playerBScore, this.state.playerAMatchScore + this.state.playerBMatchScore) : null
        this.state.validateScores ? this.validateScores(this.state.playerAScore, this.state.playerBScore) : null
        this.state.validateMatchScores ? this.validateMatchScore(this.state.bestOf5or7, this.state.playerAMatchScore, this.state.playerBMatchScore) : null

    }
    componentDidMount() {
        this.forceUpdate()
        this.props.navigation.setOptions({ title: this.state.TeamANameLeague + " VS " + this.state.TeamBNameLeague })
    }

    componentWillUnmount() {
        firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("tables").child("0").off("value", (val) => {
            //console.log("")
            this.setState(val.val())
            //console.log("This is the game settings")
            // this.setState((state, props) => {return {firstLoad: false}})

        })
    }

    onFieldChange(field, data, action = null) {
        //this.setState({tableData: {[field] : data}})
        this.setState({ [field]: data, validateService: true }, async () => {
            if (action != null) {

            }
        })

        firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("tables").child("0").update({ [field]: data })
    }

    onMatchChange(field, data) {
        //this.setState({tableData: {[field] : data}})
        this.setState({ [field]: data, validateService: true },)

        firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("tables").child("0").update({ [field]: data })
    }
    onStartTheGame = () => {
        if (this.state.PlayerAFirstName.length < 1 || this.state.PlayerBFirstName.length < 1 || this.state.competitionRound.length < 1) {
            //this.props.navigation.goBack()
            //this.props.navigation.navigate("EditTable", { tournamentID: this.props.route.params.matchid, tableIndex: "0" })


        }
        else {
            this.setState({ active: true, showServiceChoice: true }, () => {

            })
            firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("tables").child("0").update({ active: true })

            firebase.database().ref("seasons").child(this.props.route.params.seasonid).child("teams").child(this.state.TeamAName).once("value", (team)=>{
                let teamInfo = team.val()

                firebase.database().ref("scoreboards").child(teamInfo.scoreboardPath).update({matchId: this.props.route.params.matchid})
            })

        }

    }

    leftTimeOutPressed = () => {
        if (this.state.switch) {
            this.setState({ showBTimeOutDialog: true })
        }
        else {
            this.setState({ showATimeOutDialog: true })

        }
    }
    rightTimeOutPressed = () => {
        if (this.state.switch) {
            this.setState({ showATimeOutDialog: true })
        }
        else {
            this.setState({ showBTimeOutDialog: true })
        }
    }

    switchSidesPressed = () => {
        this.setState({ switch: this.state.switch ? false : true })
        firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("tables").child("0").update({ switch: this.state.switch ? false : true })

    }
    addLeftPoint = () => {
        if (((this.state.playerAScore >= 11 || this.state.playerBScore >= 11) && (this.state.playerAScore < 10 || this.state.playerBScore < 10)) || ((this.state.playerAScore >= 10 && this.state.playerBScore >= 10) && Math.abs(this.state.playerAScore - this.state.playerBScore) == 2)) {
            this.setState({ validateService: true })
        }
        else {
            if (this.state.switch) {
                this.checkingTheServe(this.state.playerAScore, this.state.playerBScore + 1, parseInt(this.state.playerBMatchScore) + parseInt(this.state.playerAMatchScore))
                //this.serviceSide(this.state.playerAScore, this.state.playerBScore+1, parseInt(this.state.playerBMatchScore)+parseInt(this.state.playerAMatchScore))
                this.onFieldChange("playerBScore", this.state.playerBScore + 1, "add")

                // this.setState({ playerBScore: this.state.playerBScore + 1, validateService: true })
            }
            else {
                this.checkingTheServe(this.state.playerAScore + 1, this.state.playerBScore, parseInt(this.state.playerBMatchScore) + parseInt(this.state.playerAMatchScore))
                //this.serviceSide(this.state.playerAScore +1, this.state.playerBScore, parseInt(this.state.playerBMatchScore)+parseInt(this.state.playerAMatchScore))

                this.onFieldChange("playerAScore", this.state.playerAScore + 1, "add")

                //  this.setState({ playerAScore: this.state.playerAScore + 1, validateService: true })
            }
        }


    }
    addRightPoint = () => {
        if (((this.state.playerAScore >= 11 || this.state.playerBScore >= 11) && (this.state.playerAScore < 10 || this.state.playerBScore < 10)) || ((this.state.playerAScore >= 10 && this.state.playerBScore >= 10) && Math.abs(this.state.playerAScore - this.state.playerBScore) == 2)) {
            this.setState({ validateService: true })
        }
        else {
            if (!this.state.switch) {
                this.checkingTheServe(this.state.playerAScore, this.state.playerBScore + 1, parseInt(this.state.playerBMatchScore) + parseInt(this.state.playerAMatchScore))
                //this.serviceSide(this.state.playerAScore, this.state.playerBScore+1, parseInt(this.state.playerBMatchScore)+parseInt(this.state.playerAMatchScore))

                this.onFieldChange("playerBScore", this.state.playerBScore + 1, "add")
            }
            else {
                this.checkingTheServe(this.state.playerAScore + 1, this.state.playerBScore, parseInt(this.state.playerBMatchScore) + parseInt(this.state.playerAMatchScore))
                //this.serviceSide(this.state.playerAScore +1, this.state.playerBScore, parseInt(this.state.playerBMatchScore)+parseInt(this.state.playerAMatchScore))

                this.onFieldChange("playerAScore", this.state.playerAScore + 1, "add")
            }
        }


    }
    minusLeft = () => {
        if (this.state.switch) {
            if (this.state.playerBScore == 0) {
                null
            }
            else {
                this.checkingTheServe(this.state.playerAScore, this.state.playerBScore - 1, parseInt(this.state.playerBMatchScore) + parseInt(this.state.playerAMatchScore))
                //this.serviceSide(this.state.playerAScore, this.state.playerBScore-1, parseInt(this.state.playerBMatchScore)+parseInt(this.state.playerAMatchScore))

                this.onFieldChange("playerBScore", this.state.playerBScore - 1, "minus")
            }


        }
        else {
            if (this.state.playerAScore == 0) {
                null
            }
            else {
                this.checkingTheServe(this.state.playerAScore - 1, this.state.playerBScore, parseInt(this.state.playerBMatchScore) + parseInt(this.state.playerAMatchScore))
                //this.serviceSide(this.state.playerAScore -1, this.state.playerBScore, parseInt(this.state.playerBMatchScore)+parseInt(this.state.playerAMatchScore))
                this.onFieldChange("playerAScore", this.state.playerAScore - 1, "minus")
            }

        }



    }
    minusRight = () => {
        if (!this.state.switch) {
            if (this.state.playerBScore == 0) {
                null
            }
            else {
                this.checkingTheServe(this.state.playerAScore, this.state.playerBScore - 1, parseInt(this.state.playerBMatchScore) + parseInt(this.state.playerAMatchScore))
                // this.serviceSide(this.state.playerAScore, this.state.playerBScore-1, parseInt(this.state.playerBMatchScore)+parseInt(this.state.playerAMatchScore))

                this.onFieldChange("playerBScore", this.state.playerBScore - 1, "minus")
            }



        }
        else {
            if (this.state.playerAScore == 0) { null }
            else {
                this.checkingTheServe(this.state.playerAScore - 1, this.state.playerBScore, parseInt(this.state.playerBMatchScore) + parseInt(this.state.playerAMatchScore))
                // this.serviceSide(this.state.playerAScore -1, this.state.playerBScore, parseInt(this.state.playerBMatchScore)+parseInt(this.state.playerAMatchScore))

                this.onFieldChange("playerAScore", this.state.playerAScore - 1, "minus")
            }


        }





    }

    render() {     
                                    //                           <Button style={{ borderColor: "white", borderWidth: 2, backgroundColor: !this.state.switch ? this.state.BHasTimeOut ? "#5A82D8" : "gray" : this.state.AHasTimeOut ? "#5A82D8" : "gray" }} disabled={this.state.switch ? !this.state.AHasTimeOut : !this.state.BHasTimeOut} onPress={this.rightTimeOutPressed}><Text style={{ color: "white", fontSize: 20, }}>Timeout</Text></Button>

        return (
            <NativeBaseProvider >
                <View style={{flex: 1, opacity: 1,}} 
            //    style={{ flex: 1, opacity: 0.6, width:"100%", height:"100%" }}
                >

<View style={{ justifyContent: "space-around", flexDirection: "row",backgroundColor:"#ff7800"}}>
                            <Button style={{ borderColor: "white", borderWidth: 2, backgroundColor: "#5A82D8", width:"40%"  }} onPress={this.switchSidesPressed}>
                                <Text style={{ color: "white", fontSize: 16, }}>Switch Sides</Text></Button>
                            {
                                //                            <Button style={{ borderColor: "white", borderWidth: 2, backgroundColor: this.state.switch ? this.state.BHasTimeOut ? "#5A82D8" : "gray" : this.state.AHasTimeOut ? "#5A82D8" : "gray" }} disabled={this.state.switch ? !this.state.BHasTimeOut : !this.state.AHasTimeOut} onPress={this.leftTimeOutPressed}><Text style={{ color: "white", fontSize: 20, }}>Timeout</Text></Button>

                                
                                //                            <Button style={{ borderColor: "white", borderWidth: 2, backgroundColor: !this.state.switch ? this.state.BHasTimeOut ? "#5A82D8" : "gray" : this.state.AHasTimeOut ? "#5A82D8" : "gray" }} disabled={this.state.switch ? !this.state.AHasTimeOut : !this.state.BHasTimeOut} onPress={this.rightTimeOutPressed}><Text style={{ color: "white", fontSize: 20, }}>Timeout</Text></Button>

                            }
                            <Button style={{ borderColor: "white", borderWidth: 2, backgroundColor: "#5A82D8", width:"40%" }} onPress={()=>{
                                this.setState({showEditScreen: true})
                                console.log("clicked edit")
                            }}>
                                <Text style={{ color: "white", fontSize: 16, }}>Edit</Text>
                            </Button>
                        </View>
                   
                        {
                            /* <View style={{ display: "none", alignContent: "center", alignItems: "center", backgroundColor: "transparent" }}><View style={{ justifyContent: "space-between", flexDirection: "row", width: "100%" }}>
                            {

                            }
                            <Button style={{ borderColor: "white", borderWidth: 2, backgroundColor: "#5A82D8" }} onPress={this.switchSidesPressed}><Text style={{ color: "white", fontSize: 20, }}>Switch Sides</Text></Button>
                            {

                            }
                        </View>
                         </View>   */
                        }
                        
                    
                    
                    {

                    }


                    <View style={{ flexDirection: "row", flex: 1, justifyContent: "space-between",  }}>

                        {
                            //###########################################################################333
                            //###################### Player A ################################################
                            //#############################################################################333
                        }

                        <View style={{ width: "50%", height: "100%", alignItems: "center", backgroundColor: this.state.switch ? this.state.ColorB ? this.state.ColorB : "red" : this.state.ColorA ? this.state.ColorA : "blue" }}>
                            {
                                // ###### Player A Start #####
                            }


                            <Text style={{ fontSize: 36, color: this.state.switch ? this.state.ColorB == "black" ? "white" : "black" : this.state.ColorA == "black" ? "white" : "black" }}>{this.state.switch ? this.state.playerBMatchScore : this.state.playerAMatchScore}</Text>
                            {
                                this.state.isTeamTournament ? <Text style={{ fontSize: 24, color: this.state.switch ? this.state.ColorB == "black" ? "white" : "black" : this.state.ColorA == "black" ? "white" : "black" }}>{this.state.switch ? this.state.TeamBNameLeague : this.state.TeamANameLeague}</Text> : null
                            }

                            {this.state.switch ? this.state.PlayerBLastName.length > 0 ? <Text style={{ fontSize: 24, color: this.state.ColorB == "black" ? "white" : "black" }} numberOfLines={1}>{this.state.PlayerBLastName}</Text>
                                : <Text style={{ fontSize: 24, color: this.state.ColorB == "black" ? "white" : "black" }} numberOfLines={1}>{this.state.PlayerBFirstName}</Text>
                                :
                                this.state.PlayerALastName.length > 0 ? <Text style={{ fontSize: 24, color: this.state.ColorA == "black" ? "white" : "black" }} numberOfLines={1}>{this.state.PlayerALastName}</Text> :
                                    <Text style={{ fontSize: 24, color: this.state.ColorA == "black" ? "white" : "black" }} numberOfLines={1}>{this.state.PlayerAFirstName}</Text>
                            }
                            {this.state.switch ? !this.state.isDoubles ? this.state.PlayerBLastName.length > 0 ?
                                <Text style={{ fontSize: 24, color: this.state.ColorB == "black" ? "white" : "black" }} numberOfLines={1}>{this.state.PlayerBFirstName}</Text> : null : null
                                :
                                !this.state.isDoubles ? this.state.PlayerALastName.length > 0 ?
                                    <Text style={{ fontSize: 24, color: this.state.ColorA == "black" ? "white" : "black" }} numberOfLines={1}>{this.state.PlayerAFirstName}</Text> : null : null
                            }

                            {
                                this.state.isDoubles ? <Text style={{ fontSize: 24, color: this.state.switch ? this.state.ColorB == "black" ? "white" : "black" : this.state.ColorA == "black" ? "white" : "black" }} numberOfLines={1}>{this.state.switch ? this.state.PlayerB2LastName.length >= 1 ? this.state.PlayerB2LastName : this.state.PlayerB2FirstName : this.state.PlayerA2LastName.length >= 1 ? this.state.PlayerA2LastName : this.state.PlayerA2FirstName}</Text>
                                    : null
                            }



                            <View style={{ padding: 10, width: "100%", height: "70%", alignItems: "center", position: "absolute", bottom: 0 }}>
                                <Button disabled={!this.state.active}
                                    title="+" onPress={this.addLeftPoint}
                                    style={{
                                        width: "100%", height: "40%",
                                        justifyContent: "center", alignItems: "center",
                                        backgroundColor: "#5A82D8", borderColor: "white", borderWidth: 4
                                    }}>

                                    <Text style={{ fontSize: 50, color: "white" }}>+</Text>

                                </Button>

                                <Text style={{ fontSize: 50, color: this.state.switch ? this.state.ColorB == "black" ? "white" : "black" : this.state.ColorA == "black" ? "white" : "black" }}>{this.state.switch ? this.state.playerBScore : this.state.playerAScore}</Text>



                                <Button disabled={!this.state.active} onPress={this.minusLeft}
                                    style={{ width: "100%", height: "40%", justifyContent: "center", alignItems: "center", backgroundColor: "#5A82D8", borderColor: "white", borderWidth: 4 }}><Text style={{ fontSize: 50, color: "white" }}>-</Text></Button>
                            </View>
                            {
                                this.state.switch ? this.state.AisServing ? null : <View style={{ position: "absolute",  right: 0, justifyContent: "center", padding: 10 }}>
                                    <Icon size={32} name="table-tennis" style={{ color: this.state.switch ? this.state.ColorB == "black" ? "white" : "black" : this.state.ColorA == "black" ? "white" : "black" }} type={"FontAwesome5"} /></View>
                                    : this.state.AisServing ? <View style={{ position: "absolute", right: 0, justifyContent: "center", padding: 10 }}>
                                        <Icon size={32} name="table-tennis" style={{  color: this.state.switch ? this.state.ColorB == "black" ? "white" : "black" : this.state.ColorA == "black" ? "white" : "black" }} type={"FontAwesome5"} /></View>
                                        : null
                            }

                            {
                                // ###### Player A End #####
                            }
                        </View>

                

                        

                        {
                            //########################################################################################
                            //########################## Player B Side #############################################
                            //#######################################################################################

                        }
                        <View style={{ width: "50%", height: "100%", alignContent: "center", alignItems: "center", backgroundColor: !this.state.switch ? this.state.ColorB ? this.state.ColorB : "red" : this.state.ColorA ? this.state.ColorA : "blue" }}>
                            {
                                // ###### Player B Start #####
                            }


                            <Text style={{ fontSize: 36, color: this.state.switch ? this.state.ColorA == "black" ? "white" : "black" : this.state.ColorB == "black" ? "white" : "black" }}>{this.state.switch ? this.state.playerAMatchScore : this.state.playerBMatchScore}</Text>
                            {
                                this.state.isTeamTournament ? <Text numberOfLines={1} style={{ fontSize: 24, color: this.state.switch ? this.state.ColorA == "black" ? "white" : "black" : this.state.ColorB == "black" ? "white" : "black" }}>{this.state.switch ? this.state.TeamANameLeague : this.state.TeamBNameLeague}</Text> : null
                            }
                            {!this.state.switch ?
                                this.state.PlayerBLastName.length > 0 ? <Text style={{ fontSize: 24, color: this.state.ColorB == "black" ? "white" : "black" }} numberOfLines={1}>{this.state.PlayerBLastName}</Text> :
                                    <Text style={{ fontSize: 24, color: this.state.ColorB == "black" ? "white" : "black" }} numberOfLines={1}>{this.state.PlayerBFirstName}</Text>
                                :
                                this.state.PlayerALastName.length > 0 ? <Text style={{ fontSize: 24, color: this.state.ColorA == "black" ? "white" : "black" }} numberOfLines={1}>{this.state.PlayerALastName}</Text> :
                                    <Text style={{ fontSize: 24, color: this.state.ColorA == "black" ? "white" : "black" }} numberOfLines={1}>{this.state.PlayerAFirstName}</Text>
                            }

                            {!this.state.switch ?
                                !this.state.isDoubles ? this.state.PlayerBLastName.length > 0 ?
                                    <Text style={{ fontSize: 24, color: this.state.ColorB == "black" ? "white" : "black" }} numberOfLines={1}>{this.state.PlayerBFirstName}</Text> : null : null
                                :
                                !this.state.isDoubles ? this.state.PlayerALastName.length > 0 ?
                                    <Text style={{ fontSize: 24, color: this.state.ColorA == "black" ? "white" : "black" }} numberOfLines={1}>{this.state.PlayerAFirstName}</Text> : null : null
                            }

                            {
                                this.state.isDoubles ? <Text style={{ fontSize: 24, color: this.state.switch ? this.state.ColorA == "black" ? "white" : "black" : this.state.ColorB == "black" ? "white" : "black" }} numberOfLines={1}>{this.state.switch ? this.state.PlayerA2LastName.length >= 1 ? this.state.PlayerA2LastName : this.state.PlayerA2FirstName : this.state.PlayerB2LastName.length >= 1 ? this.state.PlayerB2LastName : this.state.PlayerB2FirstName}</Text>
                                    : null

                            }

                            <View style={{ padding: 10, width: "100%", height: "70%", alignItems: "center", position: "absolute", bottom: 0 }}>
                                <Button disabled={!this.state.active} onPress={this.addRightPoint} style={{ width: "100%", height: "40%", justifyContent: "center", alignItems: "center", backgroundColor: "#5A82D8", borderColor: "white", borderWidth: 4 }}>
                                    <Text style={{ fontSize: 50, color: "white" }}>+</Text>
                                </Button>
                                <Text style={{ fontSize: 50, color: this.state.switch ? this.state.ColorA == "black" ? "white" : "black" : this.state.ColorB == "black" ? "white" : "black" }}>{this.state.switch ? this.state.playerAScore : this.state.playerBScore}</Text>

                                <Button disabled={!this.state.active} onPress={this.minusRight} style={{ width: "100%", height: "40%", justifyContent: "center", alignItems: "center", backgroundColor: "#5A82D8", borderColor: "white", borderWidth: 4 }}>
                                    <Text style={{ fontSize: 50, color: "white" }}>-</Text></Button>
                            </View>
                            {
                                this.state.switch ? !this.state.AisServing ? null : <View style={{ position: "absolute", left: 0, justifyContent: "center", padding: 10 }}>
                                    <Icon size={32}  name="table-tennis" type={"FontAwesome5"} style={{ color: this.state.switch ? this.state.ColorA == "black" ? "white" : "black" : this.state.ColorB == "black" ? "white" : "black" }} />
                                </View>
                                    : !this.state.AisServing ? <View style={{ position: "absolute", left: 0, justifyContent: "center", padding: 10 }}>
                                        <Icon size={32}  name="table-tennis" type={"FontAwesome5"} style={{ color: this.state.switch ? this.state.ColorA == "black" ? "white" : "black" : this.state.ColorB == "black" ? "white" : "black" }} />
                                    </View>
                                        : null
                            }
                            {
                                // ###### Player B End #####
                            }
                        </View>

                    </View>
                    {
                        /* this.state.active ? null :<View style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, justifyContent: 'center', alignItems: 'center',}}>
                         <Button full style={{height:"40%"}} onPress={()=> this.startGame()}><Text style={{color:"white"}}>Start Game {this.state.playerAMatchScore+this.state.playerBMatchScore+1}</Text></Button>
                       </View>
                       */
                    }
                    {

                        // this.state.BTimeOutActive ? <View style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, justifyContent: 'center', alignItems: 'center', }}>
                        //     <CountDown AorB={"B"} finished={this.timerIsDone} />
                        // </View> : null
                    }
                    {
                        // this.state.ATimeOutActive ? <View style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, justifyContent: 'center', alignItems: 'center', }}>
                        //     <CountDown AorB={"A"} finished={this.timerIsDone} />
                        // </View> : null
                    }


                    {
                        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                        /////////////////////////////////////////////////// serving first ////////////////////////////////////////////////////////////
                    }

                    {
                        this.state.showServiceChoice || !this.state.selectedServer ? <Dialog visible={this.state.showServiceChoice || !this.state.selectedServer}>
                            <Dialog.Content style={{ justifyContent: "center", alignItems: "center" }} ><Text>{"Who will be serving first?"}</Text></Dialog.Content>
                            <Dialog.Actions >
                                <View style={{ width: "100%", justifyContent: "space-between", flexDirection: "row" }}>
                                    <View style={{ flex: 1, width: "100%", paddingRight: 3 }}>
                                        <Button full style={{ justifyContent: "center", alignItems: "center", backgroundColor: "#5A82D8" }} onPress={() => {

                                            this.setState({ AServes: true, showServiceChoice: false, validateService: true, AisServing: true, selectedServer: true },
                                                () => {
                                                    //     var stats = new LiveStats(this.state, "matchStart")
                                                    //     //console.log(this.state)
                                                    //    // console.log(this.props.navigation.getParam("token"))
                                                    //     stats.sendGameStats()
                                                })
                                            firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("tables").child("0").update({ AServes: true, AisServing: true, selectedServer: true })

                                        }}><Text style={{ color: "white" }}>{this.state.isDoubles ? (this.state.PlayerALastName.length >= 1 ? this.state.PlayerALastName : this.state.PlayerAFirstName) + "/" + (this.state.PlayerA2LastName.length >= 1 ? this.state.PlayerA2LastName : this.state.PlayerA2FirstName) : this.state.PlayerAFirstName}</Text></Button>
                                    </View>
                                    <View style={{ flex: 1, width: "100%", paddingLeft: 3 }}>
                                        <Button full style={{ justifyContent: "center", alignItems: "center", backgroundColor: "#5A82D8" }} onPress={() => {
                                            this.setState({ AServes: false, showServiceChoice: false, validateService: true, AisServing: false, selectedServer: true }, () => {
                                                //     var stats = new LiveStats(this.state, "matchStart")
                                                //    // console.log(this.state)
                                                //     //console.log(this.props.navigation.getParam("token"))
                                                //     stats.sendGameStats()
                                            })
                                            firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("tables").child("0").update({ AServes: false, AisServing: false, selectedServer: true })

                                        }}>
                                            <Text style={{ color: "white" }}>{this.state.isDoubles ? (this.state.PlayerBLastName.length >= 1 ? this.state.PlayerBLastName : this.state.PlayerBFirstName) + "/" + (this.state.PlayerB2LastName.length >= 1 ? this.state.PlayerB2LastName : this.state.PlayerB2FirstName) : this.state.PlayerBFirstName}</Text></Button>
                                    </View>

                                </View>
                            </Dialog.Actions>
                        </Dialog> : null
                    }

{
                        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                        /////////////////////////////////////////////////// No PLAYERS////////////////////////////////////////////////////////////
                    }
                    {
                        this.state.noPlayers ? <Dialog visible={this.state.noPlayers}>
                            <Dialog.Content style={{ justifyContent: "center", alignItems: "center" }} >

                                <Text>{"One or more teams is not configured with any players. Please correct in the management site."}</Text>
                                </Dialog.Content>
                            <Dialog.Actions >
                              
                                    <View style={{ flex: 1, width: "100%", paddingLeft: 3 }}>
                                        <Button full style={{ justifyContent: "center", alignItems: "center", backgroundColor: "#5A82D8" }} onPress={() => { this.props.navigation.goBack() }}><Text style={{ color: "white" }}>OK.</Text></Button>
                                    </View>

                                
                            </Dialog.Actions>
                        </Dialog> : null
                    }

                    {
                        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                        /////////////////////////////////////////////////// Start the Game ////////////////////////////////////////////////////////////
                    }

                    {
                        !this.state.active && this.state.playerAMatchScore == 0 && this.state.playerAScore == 0 && this.state.playerBMatchScore == 0 && this.state.playerBScore == 0 ? 
                        <Dialog visible={!this.state.active && this.state.playerAMatchScore == 0 && this.state.playerAScore == 0 && this.state.playerBMatchScore == 0 && this.state.playerBScore == 0}>
                            <Dialog.Content style={{ justifyContent: "center", alignItems: "center" }} >


                                <Text>{this.state.PlayerAFirstName.length < 1 || this.state.PlayerBFirstName.length < 1 || this.state.competitionRound.length < 1 ? "Please edit the game settings to start the game." : "Start the Game?"}</Text>
                                {
                                    this.state.PlayerAFirstName.length < 1 || this.state.PlayerBFirstName.length < 1 || this.state.competitionRound.length < 1 || (this.state.isDoubles && (this.state.PlayerA2FirstName.length < 1 || this.state.PlayerB2FirstName.length < 1))?
                                        <View><View style={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                            <Text>Doubles or Singles:</Text>
                                            <Button style={{ margin: 4, minWidth: 100 }} onPress={() => {
                                                this.onFieldChange("isDoubles", this.state.isDoubles ? false : true)
                                            }}>
                                                <Text>{this.state.isDoubles ? "Doubles" : "Singles"}</Text>
                                            </Button>
                                        </View>
                                            <View>
                                                <View style={{ flexDirection: "row", justifyContent:"center", alignItems:"center" }}>
                                                    <Button style={{ width: this.state.isDoubles ?"45%":"90%", margin: 4 }} onPress={() => {
                                                        this.setState({ showHomeTeamPlayers: true })
                                                    }}><Text>{this.state.PlayerAFirstName.length > 0 ? this.state.PlayerAFirstName : "Select Host Team Player"}</Text>
                                                    </Button>
                                                    <Actionsheet style={{ zIndex: 3000 }} isOpen={this.state.showHomeTeamPlayers} onClose={() => { this.setState({ showHomeTeamPlayers: false }) }} >
                                                        <Actionsheet.Content >
                                                            {
                                                                this.state.hostingTeamPlayers.map((playerName) => {
                                                                    return (
                                                                        <Actionsheet.Item onPress={() => {
                                                                            this.setHostTeamFirstName(playerName)
                                                                            this.setState({ showHomeTeamPlayers: false })
                                                                        }}>{playerName}</Actionsheet.Item>
                                                                    )
                                                                })
                                                            }
                                                            <View style={{ height: Dimensions.get("window").height / 2, width: "100%" }}></View>
                                                        </Actionsheet.Content>

                                                    </Actionsheet>
                                                    {
                                                        this.state.isDoubles ?
                                                            <View style={{ width: "45%"}}>
                                                                <Button style={{ width: "100%", margin: 4, }} onPress={() => {
                                                                    this.setState({ showHomeTeamPlayers2: true })
                                                                }}><Text>{this.state.PlayerA2FirstName.length > 0 ? this.state.PlayerA2FirstName : "Select 2nd Host Team Player"}</Text>
                                                                </Button>
                                                                <Actionsheet style={{ zIndex: 3000 }} isOpen={this.state.showHomeTeamPlayers2} onClose={() => { this.setState({ showHomeTeamPlayers2: false }) }} >
                                                                    <Actionsheet.Content >
                                                                        {
                                                                            this.state.hostingTeamPlayers.map((playerName) => {
                                                                                return (
                                                                                    <Actionsheet.Item onPress={() => {
                                                                                        this.onFieldChange("PlayerA2FirstName", playerName)
                                                                                        //this.setHostTeamFirstName(playerName)
                                                                                        this.setState({ showHomeTeamPlayers2: false })
                                                                                    }}>{playerName}</Actionsheet.Item>
                                                                                )
                                                                            })
                                                                        }
                                                                        <View style={{ height: Dimensions.get("window").height / 2, width: "100%" }}></View>
                                                                    </Actionsheet.Content>

                                                                </Actionsheet></View>

                                                            : null
                                                    }
                                                </View>
                                                <View style={{ flexDirection: "row", width:"100%", justifyContent:"center", alignItems:"center"}}>
                                                    <Button style={{ width: this.state.isDoubles ?"45%":"90%", margin: 4, }}
                                                        onPress={() => {
                                                            this.setState({ showVisitorTeamPlayers: true })
                                                        }}
                                                    ><Text>{this.state.PlayerBFirstName.length > 0 ? this.state.PlayerBFirstName : "Select Visiting Team Player"}</Text></Button>
                                                    <Actionsheet style={{ zIndex: 101 }} isOpen={this.state.showVisitorTeamPlayers} onClose={() => { this.setState({ showVisitorTeamPlayers: false }) }} >
                                                        <Actionsheet.Content >
                                                            {
                                                                this.state.visitingTeamPlayers.map((playerName) => {
                                                                    return (
                                                                        <Actionsheet.Item key={playerName} onPress={() => {
                                                                            this.setVisitorTeamFirstName(playerName)
                                                                            this.setState({ showVisitorTeamPlayers: false })
                                                                        }}>{playerName}</Actionsheet.Item>
                                                                    )
                                                                })
                                                            }
                                                            <View style={{ height: Dimensions.get("window").height / 2, width: "100%" }}></View>
                                                        </Actionsheet.Content>

                                                    </Actionsheet>
                                                    {
                                                        this.state.isDoubles ? <View style={{ width: "45%"}}>
                                                            <Button style={{ width: "100%", margin: 4, }}
                                                                onPress={() => {
                                                                    this.setState({ showVisitorTeamPlayers2: true })
                                                                }}
                                                            ><Text>{this.state.PlayerB2FirstName.length > 0 ? this.state.PlayerB2FirstName : "Select 2nd Visiting Team Player"}</Text></Button>
                                                            <Actionsheet style={{ zIndex: 101 }} isOpen={this.state.showVisitorTeamPlayers2} onClose={() => { this.setState({ showVisitorTeamPlayers2: false }) }} >
                                                                <Actionsheet.Content >
                                                                    {
                                                                        this.state.visitingTeamPlayers.map((playerName) => {
                                                                            return (
                                                                                <Actionsheet.Item key={playerName} onPress={() => {
                                                                                    this.onFieldChange("PlayerB2FirstName", playerName)
                                                                                    //this.setVisitorTeamFirstName(playerName)
                                                                                    this.setState({ showVisitorTeamPlayers2: false })
                                                                                }}>{playerName}</Actionsheet.Item>
                                                                            )
                                                                        })
                                                                    }
                                                                    <View style={{ height: Dimensions.get("window").height / 2, width: "100%" }}></View>
                                                                </Actionsheet.Content>
                                                            </Actionsheet>

                                                        </View> : null
                                                    }</View>
                                            </View></View> :
                                        null
                                }
                            </Dialog.Content>
                            <Dialog.Actions >
                                <View style={{ width: "100%", justifyContent: "space-between", flexDirection: "row" }}>
                                    <View style={{ flex: 1, width: "100%", paddingRight: 3 }}>
                                        <Button full style={{ justifyContent: "center", alignItems: "center", backgroundColor: "#5A82D8" }} onPress={this.onStartTheGame}><Text style={{ color: "white" }}>{this.state.PlayerAFirstName.length < 1 || this.state.PlayerBFirstName.length < 1 || this.state.competitionRound.length < 1 ? "Edit Game Settings" : "Start"}</Text></Button>
                                    </View>



                                </View>
                            </Dialog.Actions>
                        </Dialog> : null
                    }





                    {
                        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                        /////////////////////////////////////////////////// A won the game ////////////////////////////////////////////////////////////
                    }
                    {
                        this.state.showAWonGameDialog ? <Dialog visible={this.state.showAWonGameDialog} >
                            <Dialog.Content style={{ justifyContent: "center", alignItems: "center" }} ><Text>{"Are you sure " + (this.state.isDoubles ? (this.state.PlayerALastName.length >= 1 ? this.state.PlayerALastName : this.state.PlayerAFirstName) + "/" + (this.state.PlayerA2LastName.length >= 0 ? this.state.PlayerA2LastName : this.state.PlayerA2FirstName) : this.state.PlayerAFirstName) + " won the game?"}</Text></Dialog.Content>
                            <Dialog.Actions >
                                <View style={{ width: "100%", justifyContent: "space-between", flexDirection: "row" }}>
                                    <View style={{ flex: 1, width: "100%", paddingRight: 3 }}>
                                        <Button full style={{ justifyContent: "center", alignItems: "center", backgroundColor: "#5A82D8" }} onPress={() => {
                                            var AscoreToSubmit = this.state.playerAScore
                                            var BscoreToSubmit = this.state.playerBScore
                                            //console.log(AscoreToSubmit, BscoreToSubmit)

                                            if (this.state.playerAScore >= 11 && this.state.playerBScore < 10) {
                                                this.state.playerAScore > 11 ? AscoreToSubmit = 11 : null


                                            }
                                            else if (this.state.playerBScore >= 11 && this.state.playerAScore < 10) {
                                                this.state.playerBScore > 11 ? BscoreToSubmit = 11 : null

                                            }
                                            if (this.state.AScores) {
                                                this.state.AScores.push(AscoreToSubmit)
                                                firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("tables").child("0").child("AScores").set(this.state.AScores)


                                            }
                                            else {
                                                this.setState({ AScores: [AscoreToSubmit] })
                                                firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("tables").child("0").child("AScores").set([AscoreToSubmit])
                                            }
                                            if (this.state.BScores) {
                                                this.state.BScores.push(BscoreToSubmit)
                                                firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("tables").child("0").child("BScores").set(this.state.BScores)

                                            }
                                            else {
                                                this.setState({ BScores: [BscoreToSubmit] })
                                                firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("tables").child("0").child("BScores").set([BscoreToSubmit])
                                            }
                                            this.checkingTheServe(0, 0, this.state.playerAMatchScore + this.state.playerBMatchScore + 1)
                                            // var stats = new LiveStats(this.state, "gameEnd")
                                            // stats.sendGameStats()

                                            this.setState({ playerAMatchScore: this.state.playerAMatchScore + 1, playerAScore: 0, playerBScore: 0, showAWonGameDialog: false, validateScores: true, switch: this.state.switch ? false : true, validateMatchScores: true, validateService: true })
                                            firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("tables").child("0").update({ playerAMatchScore: this.state.playerAMatchScore + 1, playerAScore: 0, playerBScore: 0, switch: this.state.switch ? false : true, })



                                        }}>
                                            <Text style={{ color: "white" }}>Yes</Text></Button>
                                    </View>
                                    <View style={{ flex: 1, width: "100%", paddingLeft: 3 }}>
                                        <Button full style={{ justifyContent: "center", alignItems: "center", backgroundColor: "#5A82D8" }} onPress={() => {
                                            // var stats = new LiveStats(this.state, "minus")

                                            this.setState({ playerAScore: this.state.playerAScore - 1, showAWonGameDialog: false, validateScores: true, validateMatchScores: true, validateService: true })
                                            firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("tables").child("0").update({ playerAScore: this.state.playerAScore - 1 })

                                        }}><Text style={{ color: "white" }}>No</Text></Button>
                                    </View>

                                </View>
                            </Dialog.Actions>
                        </Dialog> : null
                    }

                    {
                        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                        /////////////////////////////////////////////////// B won the game ////////////////////////////////////////////////////////////
                    }
                    {
                        this.state.showBWonGameDialog ? <Dialog visible={this.state.showBWonGameDialog}>
                            <Dialog.Content style={{ justifyContent: "center", alignItems: "center" }} ><Text>{"Are you sure " + (this.state.isDoubles ? (this.state.PlayerBLastName.length >= 1 ? this.state.PlayerBLastName : this.state.PlayerBFirstName) + "/" + (this.state.PlayerB2LastName.length >= 1 ? this.state.PlayerB2LastName : this.state.PlayerB2FirstName) : this.state.PlayerBFirstName) + " won the game?"}</Text></Dialog.Content>
                            <Dialog.Actions >
                                <View style={{ width: "100%", justifyContent: "space-between", flexDirection: "row" }}>
                                    <View style={{ flex: 1, width: "100%", paddingRight: 3 }}>
                                        <Button full style={{ justifyContent: "center", alignItems: "center", backgroundColor: "#5A82D8" }} onPress={() => {
                                            var AscoreToSubmit = this.state.playerAScore
                                            var BscoreToSubmit = this.state.playerBScore
                                            console.log(AscoreToSubmit, BscoreToSubmit)
                                            if (this.state.playerAScore >= 11 && this.state.playerBScore < 10) {
                                                this.state.playerAScore > 11 ? AscoreToSubmit = 11 : null


                                            }
                                            else if (this.state.playerBScore >= 11 && this.state.playerAScore < 10) {
                                                this.state.playerBScore > 11 ? BscoreToSubmit = 11 : null

                                            }
                                            if (this.state.AScores) {
                                                this.state.AScores.push(AscoreToSubmit)
                                                firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("tables").child("0").child("AScores").set(this.state.AScores)

                                            }
                                            else {
                                                firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("tables").child("0").child("AScores").set([AscoreToSubmit])

                                            }
                                            if (this.state.BScores) {
                                                this.state.BScores.push(BscoreToSubmit)
                                                firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("tables").child("0").child("BScores").set(this.state.BScores)

                                            }
                                            else {
                                                firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("tables").child("0").child("BScores").set([BscoreToSubmit])

                                            }
                                            this.checkingTheServe(0, 0, this.state.playerAMatchScore + this.state.playerBMatchScore + 1)
                                            // var stats = new LiveStats(this.state, "gameEnd")
                                            // stats.sendGameStats()
                                            this.setState({ playerBMatchScore: this.state.playerBMatchScore + 1, playerAScore: 0, playerBScore: 0, showBWonGameDialog: false, validateScores: true, switch: this.state.switch ? false : true, validateMatchScores: true, validateService: true })
                                            firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("tables").child("0").update({ playerBMatchScore: this.state.playerBMatchScore + 1, playerAScore: 0, playerBScore: 0, switch: this.state.switch ? false : true, })

                                        }}><Text style={{ color: "white" }}>Yes</Text></Button>
                                    </View>
                                    <View style={{ flex: 1, width: "100%", paddingLeft: 3 }}>
                                        <Button full style={{ justifyContent: "center", alignItems: "center", backgroundColor: "#5A82D8" }} onPress={() => {
                                            // var stats = new LiveStats(this.state, "minus")
                                            this.setState({ playerBScore: this.state.playerBScore - 1, showBWonGameDialog: false, validateScores: true, validateMatchScores: true, validateService: true })
                                            firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("tables").child("0").update({ playerBScore: this.state.playerBScore - 1, })

                                        }}>
                                            <Text style={{ color: "white" }}>No</Text></Button>
                                    </View>

                                </View>
                            </Dialog.Actions>
                        </Dialog> : null
                    }

                    {
                        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                        /////////////////////////////////////////////////// A called timeout ////////////////////////////////////////////////////////////
                    }

                    {
                        this.state.showATimeOutDialog ? <Dialog visible={this.state.showATimeOutDialog}>
                            <Dialog.Content style={{ justifyContent: "center", alignItems: "center" }}><Text>{"Start the timeout called by " + (this.state.isDoubles ? this.state.PlayerAFirstName + "/" + this.state.PlayerA2FirstName : this.state.PlayerAFirstName) + "?"}</Text></Dialog.Content>
                            <Dialog.Actions >
                                <View style={{ width: "100%", justifyContent: "space-between", flexDirection: "row" }}>
                                    <View style={{ flex: 1, width: "100%", paddingRight: 3 }}>
                                        <Button full style={{ justifyContent: "center", alignItems: "center", backgroundColor: "#5A82D8" }} onPress={() => {
                                            this.setState({ ATimeOutActive: true, showATimeOutDialog: false }, async () => {
                                                // var stats = new LiveStats(this.state, "timeout")
                                                // stats.sendGameStats()
                                            })
                                            firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("tables").child("0").update({ ATimeOutActive: true })

                                        }}><Text style={{ color: "white" }}>Yes</Text></Button>
                                    </View>
                                    <View style={{ flex: 1, width: "100%", paddingLeft: 3 }}>
                                        <Button full style={{ justifyContent: "center", alignItems: "center", backgroundColor: "#5A82D8" }} onPress={() => { this.setState({ showATimeOutDialog: false }) }}><Text style={{ color: "white" }}>No</Text></Button>
                                    </View>

                                </View>
                            </Dialog.Actions>
                        </Dialog> : null
                    }

                    {
                            /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                        /////////////////////////////////////////////////// Edit Screen ////////////////////////////////////////////////////////////
                    }
                        <Dialog visible={this.state.showEditScreen}>
                        <Dialog.Content style={{ justifyContent: "center", alignItems: "center" }} >
                            {
                              
                                    <ScrollView><View style={{ flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                        <Text>Doubles or Singles:</Text>
                                        <Button style={{ margin: 4, minWidth: 100 ,backgroundColor:"#5A82D8" }} onPress={() => {
                                            this.onFieldChange("isDoubles", this.state.isDoubles ? false : true)
                                        }}>
                                            <Text style={{ color: "white" }}>{this.state.isDoubles ? "Doubles" : "Singles"}</Text>
                                        </Button>
                                    </View>
                                        <View>
                                            <View style={{ flexDirection: "column", justifyContent:"center", alignItems:"center" }}>
                                                <Text>{this.state.TeamANameLeague} Players:</Text>
                                                <Button style={{  margin: 4,backgroundColor:"#5A82D8" }} onPress={() => {
                                                    this.setState({ showHomeTeamPlayers: true })
                                                }}><Text style={{ color: "white" }}>{this.state.PlayerAFirstName.length > 0 ? this.state.PlayerAFirstName : "Select Host Team Player"}</Text>
                                                </Button>
                                                <Actionsheet style={{ zIndex: 3000 }} isOpen={this.state.showHomeTeamPlayers} onClose={() => { this.setState({ showHomeTeamPlayers: false }) }} >
                                                    <Actionsheet.Content >
                                                        {
                                                            this.state.hostingTeamPlayers.map((playerName) => {
                                                                return (
                                                                    <Actionsheet.Item onPress={() => {
                                                                        this.setHostTeamFirstName(playerName)
                                                                        this.setState({ showHomeTeamPlayers: false })
                                                                    }}>{playerName}</Actionsheet.Item>
                                                                )
                                                            })
                                                        }
                                                        <View style={{ height: Dimensions.get("window").height / 2, width: "100%" }}></View>
                                                    </Actionsheet.Content>

                                                </Actionsheet>
                                                {
                                                    this.state.isDoubles ?
                                                        <View >
                                                            <Button style={{margin: 4, backgroundColor:"#5A82D8", }} onPress={() => {
                                                                this.setState({ showHomeTeamPlayers2: true })
                                                            }}><Text style={{ color: "white", }}>{this.state.PlayerA2FirstName.length > 0 ? this.state.PlayerA2FirstName : "Select 2nd Host Team Player"}</Text>
                                                            </Button>
                                                            <Actionsheet style={{ zIndex: 3000 }} isOpen={this.state.showHomeTeamPlayers2} onClose={() => { this.setState({ showHomeTeamPlayers2: false }) }} >
                                                                <Actionsheet.Content >
                                                                    {
                                                                        this.state.hostingTeamPlayers.map((playerName) => {
                                                                            return (
                                                                                <Actionsheet.Item onPress={() => {
                                                                                    this.onFieldChange("PlayerA2FirstName", playerName)
                                                                                    //this.setHostTeamFirstName(playerName)
                                                                                    this.setState({ showHomeTeamPlayers2: false })
                                                                                }}>{playerName}</Actionsheet.Item>
                                                                            )
                                                                        })
                                                                    }
                                                                    <View style={{ height: Dimensions.get("window").height / 2, width: "100%" }}></View>
                                                                </Actionsheet.Content>

                                                            </Actionsheet></View>

                                                        : null
                                                }
                                            </View>
                                            <View style={{ flexDirection: "column", width:"100%", justifyContent:"center", alignItems:"center"}}>
                                            <Text>{this.state.TeamBNameLeague} Players:</Text>
                                                <Button style={{  margin: 4,backgroundColor:"#5A82D8" }}
                                                    onPress={() => {
                                                        this.setState({ showVisitorTeamPlayers: true })
                                                    }}
                                                ><Text style={{ color: "white" }}>{this.state.PlayerBFirstName.length > 0 ? this.state.PlayerBFirstName : "Select Visiting Team Player"}</Text></Button>
                                                <Actionsheet style={{ zIndex: 101 }} isOpen={this.state.showVisitorTeamPlayers} onClose={() => { this.setState({ showVisitorTeamPlayers: false }) }} >
                                                    <Actionsheet.Content >
                                                        {
                                                            this.state.visitingTeamPlayers.map((playerName) => {
                                                                return (
                                                                    <Actionsheet.Item key={playerName} onPress={() => {
                                                                        this.setVisitorTeamFirstName(playerName)
                                                                        this.setState({ showVisitorTeamPlayers: false })
                                                                    }}>{playerName}</Actionsheet.Item>
                                                                )
                                                            })
                                                        }
                                                        <View style={{ height: Dimensions.get("window").height / 2, width: "100%" }}></View>
                                                    </Actionsheet.Content>

                                                </Actionsheet>
                                                {
                                                    this.state.isDoubles ? <View >
                                                        <Button style={{  margin: 4,backgroundColor:"#5A82D8" }}
                                                            onPress={() => {
                                                                this.setState({ showVisitorTeamPlayers2: true })
                                                            }}
                                                        ><Text style={{ color: "white" }}>{this.state.PlayerB2FirstName.length > 0 ? this.state.PlayerB2FirstName : "Select 2nd Visiting Team Player"}</Text></Button>
                                                        <Actionsheet style={{ zIndex: 101 }} isOpen={this.state.showVisitorTeamPlayers2} onClose={() => { this.setState({ showVisitorTeamPlayers2: false }) }} >
                                                            <Actionsheet.Content >
                                                                {
                                                                    this.state.visitingTeamPlayers.map((playerName) => {
                                                                        return (
                                                                            <Actionsheet.Item key={playerName} onPress={() => {
                                                                                this.onFieldChange("PlayerB2FirstName", playerName)
                                                                                //this.setVisitorTeamFirstName(playerName)
                                                                                this.setState({ showVisitorTeamPlayers2: false })
                                                                            }}>{playerName}</Actionsheet.Item>
                                                                        )
                                                                    })
                                                                }
                                                                <View style={{ height: Dimensions.get("window").height / 2, width: "100%" }}></View>
                                                            </Actionsheet.Content>
                                                        </Actionsheet>

                                                    </View> : null
                                                }</View>
                                        </View>
                                        <View style={{width:"100%", flexDirection:"column", justifyContent:"center", alignItems:"center" }}>
                                            <Text>Starting serve:</Text>
                                        <View style={{ flex: 1,  paddingRight: 3}}>
                                        <Button full style={{ margin:4, justifyContent: "center", alignItems: "center", backgroundColor: this.state.AServes ?  "#5A82D8": "gray" }} onPress={() => {

                                            this.setState({ AServes: true, showServiceChoice: false, validateService: true, },
                                                () => {
                                                    //     var stats = new LiveStats(this.state, "matchStart")
                                                    //     //console.log(this.state)
                                                    //    // console.log(this.props.navigation.getParam("token"))
                                                    //     stats.sendGameStats()
                                                    this.checkingTheServe(this.state.playerAScore, this.state.playerBScore, this.state.playerAMatchScore+this.state.playerBMatchScore)

                                                })
                                            firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("tables").child("0").update({ AServes: true})

                                        }}><Text style={{ color: "white" }}>{this.state.TeamANameLeague}</Text></Button>
                                    </View>
                                    <View style={{ flex: 1, paddingLeft: 3 }}>
                                        <Button full style={{ justifyContent: "center", alignItems: "center", backgroundColor: this.state.AServes ? "gray": "#5A82D8" }} onPress={() => {
                                            this.setState({ AServes: false, showServiceChoice: false, validateService: true}, () => {
                                                //     var stats = new LiveStats(this.state, "matchStart")
                                                //    // console.log(this.state)
                                                //     //console.log(this.props.navigation.getParam("token"))
                                                //     stats.sendGameStats()
                                                this.checkingTheServe(this.state.playerAScore, this.state.playerBScore, this.state.playerAMatchScore+this.state.playerBMatchScore)
                                            })
                                            firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("tables").child("0").update({ AServes: false, })

                                        }}>
                                            <Text style={{ color: "white" }}>{this.state.TeamBNameLeague}</Text></Button>
                                    </View>
                                    </View>
                                        </ScrollView> 
                            }
                        </Dialog.Content>
                        <Dialog.Actions >
                            <View style={{ width: "100%", justifyContent: "flex-end", flexDirection: "row" }}>
                                <View style={{  width: "50%", paddingRight: 3 }}>
                                    <Button  style={{ justifyContent: "center", alignItems: "flex-end", backgroundColor: "#5A82D8" }} onPress={()=>{this.setState({showEditScreen:false})}}><Text style={{ color: "white" }}>Close</Text></Button>
                                </View>



                            </View>
                        </Dialog.Actions>
                    </Dialog>
                    

                    {
                        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                        /////////////////////////////////////////////////// B called timeout ////////////////////////////////////////////////////////////
                    }
                    {
                        this.state.showBTimeOutDialog ? <Dialog visible={this.state.showBTimeOutDialog}>
                            <Dialog.Content style={{ justifyContent: "center", alignItems: "center" }} ><Text>{"Start the timeout called by " + (this.state.isDoubles ? this.state.PlayerBFirstName + "/" + this.state.PlayerB2FirstName : this.state.PlayerBFirstName) + "?"}</Text></Dialog.Content>
                            <Dialog.Actions >
                                <View style={{ width: "100%", justifyContent: "space-between", flexDirection: "row" }}>
                                    <View style={{ flex: 1, width: "100%", paddingRight: 3 }}>
                                        <Button full style={{ justifyContent: "center", alignItems: "center", backgroundColor: "#5A82D8" }} onPress={() => {

                                            this.setState({ BTimeOutActive: true, timeOutTime: 60, showBTimeOutDialog: false }, async () => {
                                                // var stats = new LiveStats(this.state, "timeout")
                                                // stats.sendGameStats()
                                            })
                                            firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("tables").child("0").update({ BTimeOutActive: true })

                                        }}><Text style={{ color: "white" }}>Yes</Text></Button>
                                    </View>
                                    <View style={{ flex: 1, width: "100%", paddingLeft: 3 }}>
                                        <Button full style={{ justifyContent: "center", alignItems: "center", backgroundColor: "#5A82D8" }} onPress={() => { this.setState({ showBTimeOutDialog: false }) }}><Text style={{ color: "white" }}>No</Text></Button>
                                    </View>

                                </View>
                            </Dialog.Actions>
                        </Dialog> : null
                    }

{
                        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                        /////////////////////////////////////////////////// No PLAYERS////////////////////////////////////////////////////////////
                    }
                    {
                        this.state.noPlayers ? <Dialog visible={this.state.noPlayers}>
                            <Dialog.Content style={{ justifyContent: "center", alignItems: "center" }} >

                                <Text>{"One or more teams is not configured with any players. Please correct in the management site."}</Text>
                                </Dialog.Content>
                            <Dialog.Actions >
                              
                                    <View style={{ flex: 1, width: "100%", paddingLeft: 3 }}>
                                        <Button full style={{ justifyContent: "center", alignItems: "center", backgroundColor: "#5A82D8" }} onPress={() => { this.props.navigation.goBack() }}><Text style={{ color: "white" }}>OK.</Text></Button>
                                    </View>

                                
                            </Dialog.Actions>
                        </Dialog> : null
                    }


                    {
                        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                        /////////////////////////////////////////////////// A Won Match ////////////////////////////////////////////////////////////
                    }
                    {
                        this.state.showAWonMatchDialog ? <Dialog visible={this.state.showAWonMatchDialog}>
                            <Dialog.Content style={{ justifyContent: "center", alignItems: "center" }}><Text>{(this.state.isDoubles ? (this.state.PlayerALastName.length >= 1 ? this.state.PlayerALastName : this.state.PlayerAFirstName) + "/" + (this.state.PlayerA2LastName.length >= 0 ? this.state.PlayerA2LastName : this.state.PlayerA2FirstName) : this.state.PlayerAFirstName) + " won the match!"}</Text></Dialog.Content>
                            <Dialog.Actions >
                                <View style={{ width: "100%", justifyContent: "space-between", flexDirection: "row" }}>
                                    <View style={{ flex: 1, width: "100%", paddingRight: 3 }}>
                                        <Button full style={{ justifyContent: "center", alignItems: "center", backgroundColor: "#5A82D8" }} onPress={() => {
                                            this.setState({ showAWonMatchDialog: false, active: false, showNewGameDialog: true }, async () => {
                                                // var stats = new LiveStats(this.state, "matchEnd")
                                                // stats.sendGameStats()
                                            })
                                            firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("finishedGames").child(this.state.competitionRound ? this.state.competitionRound : "Group Stage").push(this.state)
                                            /*
                                            firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("TeamAWins").once("value", (data) => {
                                                var gamesWon_int = parseInt(data.val())
                                                firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).update({ TeamAWins: gamesWon_int + 1 })
                                            })
                                            */

                                            /*this.setState({
                                                playerAMatchScore : 0,
                        playerAScore : 0,
                        playerBMatchScore : 0,
                        playerBScore : 0,
                        //schoolAName : "",
                        PlayerAFirstName : "",
                        PlayerALastName: "",
                        //schoolBName : "UCI",
                        PlayerBFirstName : "",
                        PlayerBLastName: "",
                        showFlags: false,
                        isDoubles: false,
                        PlayerA2FirstName : "",
                        PlayerA2LastName: "",
                        PlayerB2FirstName : "",
                        PlayerB2LastName: "",
                        ColorA: '#E74C3C',
                        ColorB:'#E74C3C',
                        AScores: [],
                        BScores: [],
                        status: "inactive",
                        switch: false,
                        competitionRound: "",
                        AHasTimeOut: true,
                        BHasTimeOut: true,
                        ATimeOutActive: false,
                        BTimeOutActive: false,
                        showColor: false, 
                        editing: false,
                        bestOf5or7: "5",
                        active: false}) */
                                        }}><Text style={{ color: "white" }}>Yes</Text></Button>
                                    </View>

                                    {
                                        /*
                                        <View style={{flex:1, width: "100%", paddingLeft:3}}>
                                        <Button full style={{ justifyContent:"center", alignItems:"center"}}  onPress={() => {this.setState({showAWonMatchDialog: false, playerAMatchScore: this.state.playerAMatchScore -1, playerAScore: this.state.playerAScore -1, validateMatchScores: true})}}>
                                        <Text style={{color:"white"}}>No</Text></Button>
                                    </View>
                                        */
                                    }


                                </View>
                            </Dialog.Actions>
                        </Dialog> : null
                    }


                    {
                        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                        /////////////////////////////////////////////////// B Won Match ////////////////////////////////////////////////////////////
                    }
                    {
                        this.state.showBWonMatchDialog ? <Dialog visible={this.state.showBWonMatchDialog}>
                            <Dialog.Content style={{ justifyContent: "center", alignItems: "center" }}><Text>{(this.state.isDoubles ? (this.state.PlayerBLastName.length >= 1 ? this.state.PlayerBLastName : this.state.PlayerBFirstName) + "/" + (this.state.PlayerB2LastName.length >= 1 ? this.state.PlayerB2LastName : this.state.PlayerB2FirstName) : this.state.PlayerBFirstName) + " won the match!"}</Text></Dialog.Content>
                            <Dialog.Actions >
                                <View style={{ width: "100%", justifyContent: "space-between", flexDirection: "row" }}>
                                    <View style={{ flex: 1, width: "100%", paddingRight: 3 }}>
                                        <Button full style={{ justifyContent: "center", alignItems: "center", backgroundColor: "#5A82D8" }} onPress={() => {

                                            this.setState({ showBWonMatchDialog: false, active: false, showNewGameDialog: true }, async () => {
                                                // var stats = new LiveStats(this.state, "matchEnd")
                                                // stats.sendGameStats()
                                            })
                                            firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("finishedGames").child("League Match").push(this.state)
                                            /*
                                            firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("TeamBWins").once("value", (data) => {
                                                var gamesWon_int = parseInt(data.val())
                                                firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).update({ TeamBWins: gamesWon_int + 1 })
                                            })
                                            */


                                        }}><Text style={{ color: "white" }}>Yes</Text></Button>
                                    </View>

                                    {
                                        /*
                                        <View style={{flex:1, width: "100%", paddingLeft:3}}>
                                        <Button full style={{ justifyContent:"center", alignItems:"center"}}  onPress={() => {this.setState({showBWonMatchDialog: false, playerBMatchScore: this.state.playerBMatchScore -1, playerBScore: this.state.playerBScore -1, validateMatchScores: true})}}>
                                        <Text style={{color:"white"}}>No</Text></Button>
                                    </View>
                                        */
                                    }


                                </View>
                            </Dialog.Actions>
                        </Dialog> : null
                    }


                    {
                        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                        /////////////////////////////////////////////////// Start New Game ////////////////////////////////////////////////////////////
                    }
                    {
                        this.state.showNewGameDialog ? <Dialog visible={this.state.showNewGameDialog}>
                            <Dialog.Content style={{ justifyContent: "center", alignItems: "center" }}><Text>{"Start a New Game?"}</Text></Dialog.Content>
                            <Dialog.Actions >
                                <View style={{ width: "100%", justifyContent: "space-between", flexDirection: "row" }}>
                                    <View style={{ flex: 1, width: "100%", paddingRight: 3 }}>
                                        <Button full style={{ justifyContent: "center", alignItems: "center", backgroundColor: "#5A82D8" }} onPress={() => {
                                            this.setState({ showNewGameDialog: false })
                                            var newGame = new Game(this.state.tournamentName, this.state.tableName, true)
                                            newGame.league_Game(this.state.TeamANameLeague, this.state.TeamBNameLeague)
                                            newGame.set_Color(this.state.ColorA, this.state.ColorB)
                                            newGame.selectedServer = false
                                            newGame.set_best_of(this.state.bestOf5or7)
                                            newGame.set_scoreboard_direction(this.state.scorboardDirectionAway)

                                            this.setState(newGame)
                                            /* this.setState({
                                                 playerAMatchScore: 0,
                                                 playerAScore: 0,
                                                 playerBMatchScore: 0,
                                                 playerBScore: 0,
                                                 //schoolAName : "",
                                                 PlayerAFirstName: "",
                                                 PlayerALastName: "",
                                                 //schoolBName : "UCI",
                                                 PlayerBFirstName: "",
                                                 PlayerBLastName: "",
                                                 showFlags: false,
                                                 isDoubles: false,
                                                 PlayerA2FirstName: "",
                                                 PlayerA2LastName: "",
                                                 PlayerB2FirstName: "",
                                                 PlayerB2LastName: "",
                                                 ColorA: 'dodgerblue',
                                                 ColorB: 'silver',
                                                 AScores: [],
                                                 BScores: [],
                                                 status: "inactive",
                                                 switch: false,
                                                 competitionRound: "",
                                                 AHasTimeOut: true,
                                                 BHasTimeOut: true,
                                                 ATimeOutActive: false,
                                                 BTimeOutActive: false,
                                                 showColor: false,
                                                 editing: false,
                                                 bestOf5or7: "5",
                                                 //validateMatchScores: true,
                                                 active: false,
                                                 //validateService: true,
                                                 currentUmpire: this.state.currentUmpire,
                                                 scoreboardTheme: this.state.scoreboardTheme,
                                                 scorboardDirectionAway: this.state.scorboardDirectionAway,
                                                 selectedServer: false
                                             }) */
                                            firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("tables").child("0").update(
                                                newGame,

                                                /*
                                                {
                                                playerAMatchScore: 0,
                                                playerAScore: 0,
                                                playerBMatchScore: 0,
                                                playerBScore: 0,
                                                //schoolAName : "",
                                                PlayerAFirstName: "",
                                                PlayerALastName: "",
                                                //schoolBName : "UCI",
                                                PlayerBFirstName: "",
                                                PlayerBLastName: "",
                                                showFlags: false,
                                                isDoubles: false,
                                                PlayerA2FirstName: "",
                                                PlayerA2LastName: "",
                                                PlayerB2FirstName: "",
                                                PlayerB2LastName: "",
                                                ColorA: 'dodgerblue',
                                                ColorB: 'silver',
                                                AScores: [],
                                                BScores: [],
                                                status: "inactive",
                                                switch: false,
                                                competitionRound: "",
                                                AHasTimeOut: true,
                                                BHasTimeOut: true,
                                                ATimeOutActive: false,
                                                BTimeOutActive: false,
                                                showColor: false,
                                                editing: false,
                                                bestOf5or7: "5",
                                                //validateMatchScores: true,
                                                active: false,
                                                //validateService: true,
                                                currentUmpire: this.state.currentUmpire,
                                                scoreboardTheme: this.state.scoreboardTheme,
                                                scorboardDirectionAway: this.state.scorboardDirectionAway,
                                                selectedServer: false
                                            } */
                                            )

                                            //this.props.navigation.goBack()
                                            // this.props.navigation.navigate("EditLeagueTable", { tournamentID: this.props.route.params.matchid, tableIndex: "0" })


                                        }}><Text style={{ color: "white" }}>Yes</Text></Button>
                                    </View>
                                    <View style={{ flex: 1, width: "100%", paddingLeft: 3 }}>
                                        <Button full style={{ justifyContent: "center", alignItems: "center", backgroundColor: "#5A82D8" }} onPress={() => {
                                            //this.setState({showNewGameDialog: false})
                                            var newGame = new Game(this.state.tournamentName, this.state.tableName, true)
                                            newGame.league_Game(this.state.TeamANameLeague, this.state.TeamBNameLeague)
                                            newGame.set_Color(this.state.ColorA, this.state.ColorB)
                                            newGame.selectedServer = false
                                            newGame.set_best_of(this.state.bestOf5or7)
                                            newGame.set_scoreboard_direction(this.state.scorboardDirectionAway)

                                            firebase.database().ref(tournamentDB).child(this.props.route.params.matchid).child("tables").child("0").update(
                                                newGame
                                                /*
                                                {
                                                playerAMatchScore: 0,
                                                playerAScore: 0,
                                                playerBMatchScore: 0,
                                                playerBScore: 0,
                                                //schoolAName : "",
                                                PlayerAFirstName: "",
                                                PlayerALastName: "",
                                                //schoolBName : "UCI",
                                                PlayerBFirstName: "",
                                                PlayerBLastName: "",
                                                showFlags: false,
                                                isDoubles: false,
                                                PlayerA2FirstName: "",
                                                PlayerA2LastName: "",
                                                PlayerB2FirstName: "",
                                                PlayerB2LastName: "",
                                                ColorA: 'dodgerblue',
                                                ColorB: 'silver',
                                                AScores: [],
                                                BScores: [],
                                                status: "inactive",
                                                switch: false,
                                                competitionRound: "",
                                                AHasTimeOut: true,
                                                BHasTimeOut: true,
                                                ATimeOutActive: false,
                                                BTimeOutActive: false,
                                                showColor: false,
                                                editing: false,
                                                bestOf5or7: "5",
                                                //validateMatchScores: true,
                                                active: false,
                                                //validateService: true,
                                                currentUmpire: this.state.currentUmpire,
                                                scoreboardTheme: this.state.scoreboardTheme,
                                                scorboardDirectionAway: this.state.scorboardDirectionAway,
                                                selectedServer: false
                                            }
                                            */
                                            )
                                            //this.props.navigation.goBack()
                                        }}><Text style={{ color: "white" }}>No</Text></Button>
                                    </View>

                                </View>
                            </Dialog.Actions>
                        </Dialog> : null
                    }



                </View>
            </NativeBaseProvider>
        )
    }
}