

class  LAPPWordPressPosts {
    constructor(WPPostJSON){
        // try{
            this.PostJSON = WPPostJSON
        // }
        // catch(err){
        //     throw new Error("JSON was not passed.")
        // }
        try {
           this.parseRawJSON() 
        } catch (error) {
            throw new Error("Unable to parse the JSON provided. Make sure this is the raw json from the wordpress api."+error)
        }
    }

    parseRawJSON(){
        var postList = []
        var basicFields = ["id", "date","link"]
        var renderedFields = ["title", "excerpt"]

        for (const post of this.PostJSON) {
            var formattedPost = {}
            for (const basicField of basicFields) {
                formattedPost[basicField] = post[basicField]
            }
            for (const renderedField of renderedFields) {
                formattedPost[renderedField] = post[renderedField]["rendered"]
            }
            if(typeof post["_links"] != "undefined"){
                formattedPost["mediaURL"] = post["_links"]["wp:attachment"][0]["href"]
            }
            else{
                formattedPost["mediaURL"] = null
            }
            formattedPost["photoURLList"]= []
            postList.push(formattedPost)
        }
        this.formattedPosts = postList
    }

    addPhotoLinkToPost(postID, photoURL){
        var postIndex = this.formattedPosts.findIndex((value, index)=>{
            return value["id"] == postID
        })
        this.formattedPosts[postIndex]["photoURLList"].push(photoURL)
    }

//    async getMediaPhotoURLs(RAWPost){
//         if(typeof RAWPost["_links"] != "undefined"){
//             var photoURL = RAWPost["_links"]["wp:attachment"][0]
//             var mediaList = await axios.get(photoURL)
//             console.log(mediaList.data)

//         }
//     }
}

module.exports= {
    LAPPWordPressPosts:LAPPWordPressPosts
}
