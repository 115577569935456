import React from 'react';
import { Image, Animated, Easing, Text, ScrollView, FlatList, Linking, TextInput, View } from 'react-native'
import {
    Card,
    //    Button
} from 'react-native-paper'
import { parse } from 'node-html-parser';
import Clipboard from '@react-native-clipboard/clipboard';
import {
    // Icon, 
    Input, NativeBaseProvider, TextField, IconButton, Button, Toast
} from 'native-base';

import Icon from 'react-native-vector-icons/FontAwesome';
const CopyButton = (props) => {
    return (
        <Button style={{...props.style, backgroundColor:"#ff7800"}} 
        onPress={()=>{
            Clipboard.setString(props.copyText)
            // Toast.show({description:"Text Copied!"})
            props.showToast()
        }}>
            <Icon name="copy" size={30} color="black" />
        </Button>
    )
}





function InstructionItem(props) {
    const { title,
        photoURL,
        description,
        hasExternalLink,
        externalLinkText,
        externalLink,
        needsToCopy,
        copyLink,
        hasPhoto,
        isLocalPhoto,
        
    } = props
    return (
        <NativeBaseProvider>
            <Card style={{ borderRadius: 15, margin: 3, backgroundColor: "white",maxWidth:600, alignSelf:"center", width:"100%" }}>
                <Card.Title

                    title={title}
                    titleStyle={{ fontSize: 14, fontWeight: "bold" }}
                ></Card.Title>
                <Card.Content>
                    <Text>{description}</Text>
                    {
                        hasExternalLink ?
                            <Button style={{backgroundColor:"#ff7800"}} onPress={() => {
                                Linking.openURL(externalLink)
                            }}>
                                <Text style={{color:"black", fontWeight:"bold"}}>{externalLinkText}</Text>
                            </Button>
                            :
                            null
                    }
                    {
                        needsToCopy ?
                            <View style={{flexDirection:"row", width:"100%", justifyContent:"space-around", alignItems:"center"}}>

                                
                            <View style={{flex:8}}>
                                <Input   value={copyLink}></Input>
                            </View>
                                <View style={{flex:2}}>
                                    <CopyButton showToast={props.showToast} copyText={copyLink} />
                                </View>
                                

                            </View>
                            :
                            null

                    }

                    {
                        hasPhoto ?
                            <Card.Cover height="auto" width="100%" style={{}} source={isLocalPhoto ? photoURL :{ uri: photoURL }} /> :
                            null
                    }


                </Card.Content>


            </Card>
        </NativeBaseProvider>
    )
}
export default InstructionItem