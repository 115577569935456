import React from 'react';
import {
    FlatList,
    Image,
    SafeAreaView,
    ScrollView,
    StatusBar,
    StyleSheet,
    Text,
    useColorScheme,
    View,
    //Button,
    Linking
} from 'react-native';
import { AddIcon, IconButton, NativeBaseProvider, Modal, FormControl, Input, Select, Checkbox, HStack, VStack } from 'native-base';
import MatchItem from './components/matchitem';
import firebase from 'firebase';
import { Card, Button } from 'react-native-paper';
import League from './classes/League'

// const tempData = [{
//     matchName:"My Test Stream",
//     //matchDescription:"Jack's first test of the stream",
//     matchDate: new Date().toISOString(),
//     streamKey: "k8xm-thmt-1usc-e1km-6a3b",
//     streamURL: "rtmp://x.rtmp.youtube.com/live2/",
//     publicStreamURL: "", //when i can get from the api..
// }]

class MatchAdmin extends React.Component {

    state = {
        showNewMatch: false,
        showEditMatch: false,
        matchList: [],
        isAdmin: false,
        teamList: [],
        rows: [],
        newPlayers: [],
        selectedTeam: {
            players: []
        },
        newStartDate: new Date(new Date().setHours(9, 0)),
        newMatchName: "",
        monthDayList: [],
        newMatchTeams: [],
        newMatchTeamHome: "",
        newMatchTeamAway: "",
        startTimeAMPM: "AM",
        startTimeMinute: "00",
        startTimeHour: "9",
        editStartDate: new Date(),
        selectedMatch: {
            matchName:"",
            startDate: new Date(),
            TeamAName:"",
            TeamBName:""

        }
    }

  formatAMPM(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
      }

    DaysInAMonth(MonthIndex, Year = new Date().getFullYear()) {
        let daysInTheMonth = new Date(Year, MonthIndex + 1, 0).getDate()
        let daysInAMonthArray = []
        for (let index = 1; index <= daysInTheMonth; index++) {
            daysInAMonthArray.push(index)

        }
        console.log(daysInAMonthArray)
        this.setState({ monthDayList: daysInAMonthArray })
    }

    componentDidMount() {
        // Get request for current matches
        //then set to state.
        firebase.database()
            .ref("matches").orderByChild("seasonKey").equalTo(this.props.route.params["seasonKey"]).once("value", (data) => {
                console.log(data.val())

                this.setState({
                    matchList: data.val() == null ? [] : data.val(),
                })
            })

        firebase.database()
            .ref("seasons").child(this.props.route.params["seasonKey"]).child("teams").once("value", (data) => {
                console.log(data.val())

                this.setState({
                    teamList: data.val() == null ? [] : data.val(),
                })
            })
        this.DaysInAMonth(new Date().getMonth())

        // this.setState({matchList: tempData})
    }

    render() {
        console.log(this.state.editStartDate, typeof this.state.editStartDate)
        return (
            <NativeBaseProvider>
                <View style={{ width: "100%", height: "100%" }}>


                    <ScrollView style={{ width: "100%", height: "100%", backgroundColor: "#ff7800" }}>

                        {
                            Object.keys(this.state.matchList).sort((a,b)=>{
                                if(this.state.matchList[a].startDate > this.state.matchList[b].startDate){
                                    return -1
                                }
                                else {
                                    return 1
                                }
                            }).map((key, index) => {
                                console.log(key)
                                //console.log(this.state.teamList)
                                let match = this.state.matchList[key]
                                return (
                                    <View style={{ padding: 5, width:"100%", maxWidth:600, alignSelf:"center" }} key={key}>
                                        <Card
                                            onPress={() => {
                                                this.setState({ selectedMatchKey: key, selectedMatch: { ...this.state.matchList[key] }, showEditMatch: true, editStartDate:new Date(this.state.matchList[key].startDate) })
                                            }}
                                            style={{ padding: 5, flexDirection: "column" }} >
                                            <HStack style={{alignSelf:"center"}}>
                                                <Text style={{ fontSize: 24, padding: 5, fontWeight: "bold" }}>{this.state.teamList[this.state.matchList[key].TeamAName].teamName}</Text>
                                                <Text style={{ fontSize: 24, padding: 5, fontWeight: "bold" }}> VS </Text>
                                                <Text style={{ fontSize: 24, padding: 5, fontWeight: "bold" }}>{this.state.teamList[this.state.matchList[key].TeamBName].teamName}</Text>
                                            </HStack>
                                            <Text style={{alignSelf:"center"}}>{["January","February","March","April","May","June","July","August","September","October","November","December"][new Date(this.state.matchList[key].startDate).getMonth()]+" "+ new Date(this.state.matchList[key].startDate).getDate()+", "+new Date(this.state.matchList[key].startDate).getFullYear()+" at "+this.formatAMPM(new Date(this.state.matchList[key].startDate))}</Text>




                                            <Card.Actions>
                                                <Button
                                                onPress={()=>{
                                                    firebase.database()
            .ref("matches").child(key).child("isHidden").set(match["isHidden"] ? false:true)
                                                }}
                                                ><Text>{match["isHidden"] ? "Show":"Hide"}</Text></Button>
                                            </Card.Actions>
                                        </Card>
                                    </View>

                                )
                            })
                        }

                    </ScrollView>
                    <IconButton
                        onPress={() => {
                            this.setState({ showNewMatch: true })
                        }}
                        borderRadius={100} color={"white"} justifyContent={"center"} alignItems={"center"} position={"absolute"} bottom={5} right={5} bg="red.500" width={"1/5"} maxWidth={60} icon={<AddIcon   ></AddIcon>}></IconButton>
                    <Modal isOpen={this.state.showEditMatch} onClose={() => {
                        console.log("pressed")
                        this.setState({ showEditMatch: false })
                    }}>
                        <Modal.Content>
                            <Modal.Header><Text>Edit Match</Text></Modal.Header>
                            <Modal.CloseButton></Modal.CloseButton>
                            <Modal.Body>
                            {/* {<FormControl>
                                    <FormControl.Label>Match Name:</FormControl.Label>
                                    <Input
                                        onChangeText={(text) => {
                                            this.setState({ newMatchName: text })
                                        }}
                                        value={this.state.selectedMatch.matchName}
                                        placeholder='Match Name'></Input>
                                </FormControl>} */}

                                <FormControl>
                                    <FormControl.Label>{"Month:"}</FormControl.Label>

                                    <Select selectedValue={this.state.editStartDate.getMonth().toString()}
                                        onValueChange={(value) => {
                                            this.state.editStartDate.setMonth(value)
                                            this.setState({ editStartDate: this.state.newStartDate })
                                            this.DaysInAMonth(value)
                                        }}
                                        padding={"2"}
                                    //defaultValue={startDate.getMonth()}
                                    >
                                        <Select.Item label={"January"} value={"0"}></Select.Item>
                                        <Select.Item label={"February"} value={"1"}></Select.Item>
                                        <Select.Item label={"March"} value={"2"}></Select.Item>
                                        <Select.Item label={"April"} value={"3"}></Select.Item>
                                        <Select.Item label={"May"} value={"4"}></Select.Item>
                                        <Select.Item label={"June"} value={"5"}></Select.Item>
                                        <Select.Item label={"July"} value={"6"}></Select.Item>
                                        <Select.Item label={"August"} value={"7"}></Select.Item>
                                        <Select.Item label={"September"} value={"8"}></Select.Item>
                                        <Select.Item label={"October"} value={"9"}></Select.Item>
                                        <Select.Item label={"November"} value={"10"}></Select.Item>
                                        <Select.Item label={"December"} value={"11"}></Select.Item>
                                    </Select>

                                </FormControl>
                                <FormControl>
                                    <FormControl.Label>Date:</FormControl.Label>
                                    <Select
                                        onValueChange={(value) => {
                                            this.state.editStartDate.setDate(value)
                                            this.setState({ editStartDate: this.state.editStartDate })

                                        }}
                                        selectedValue={this.state.editStartDate.getDate()} >
                                        {
                                            this.state.monthDayList.map((day) => {
                                                return (
                                                    <Select.Item key={day} label={day} value={day}>{day}</Select.Item>
                                                )
                                            })
                                        }

                                    </Select>
                                </FormControl>
                                {
                                    // <Select
                                    //                             onValueChange={(value) => {
                                    //                                 // console.log(value)
                                    //                                 // startDate.setFullYear(value)
                                    //                                 // console.log(startDate)
                                    //                                 // setStartDate(new Date(startDate))
                                    //                                 competition.setStartDateYear(value)
                                    //                                 setCompetition(new TTNotifyCompetition(competition))

                                    //                             }}

                                    //                             maxW={100} selectedValue={competition.getStartDateYear()}>
                                    //                             <Select.Item value={new Date().getFullYear()} label={new Date().getFullYear()}></Select.Item>
                                    //                             <Select.Item value={new Date().getFullYear() + 1} label={new Date().getFullYear() + 1}></Select.Item>
                                    //                         </Select>
                                }





                                <FormControl>
                                    <FormControl.Label>Hour:</FormControl.Label>
                                    {
                                        console.log(this.state.editStartDate.getHours().toString())
                                    }

                                    <Select selectedValue={this.state.editStartDate.getHours() > 12 ? (this.state.editStartDate.getHours() -12).toString() : this.state.editStartDate.getHours().toString() } //defaultValue="9"
                                        onValueChange={(hour) => {
                                            this.state.editStartDate.setHours(this.state.startTimeAMPM == "PM" ? parseInt(hour) + 12 : hour)
                                            this.setState({ editStartDate: this.state.editStartDate, startTimeHour: this.state.startTimeAMPM == "PM" ? parseInt(hour) + 12 : hour })
                                            console.log(this.state.startTimeAMPM == "PM" ? parseInt(hour) + 12 : hour)
                                        }} >
                                        <Select.Item label='Select Hour' value=''></Select.Item>
                                        <Select.Item label='1' value={"1"}></Select.Item>
                                        <Select.Item label='2' value={"2"}></Select.Item>
                                        <Select.Item label='3' value={"3"}></Select.Item>
                                        <Select.Item label='4' value={"4"}></Select.Item>
                                        <Select.Item label='5' value={"5"}></Select.Item>
                                        <Select.Item label='6' value={"6"}></Select.Item>
                                        <Select.Item label='7' value={"7"}></Select.Item>
                                        <Select.Item label='8' value={"8"}></Select.Item>
                                        <Select.Item label='9' value={"9"}></Select.Item>
                                        <Select.Item label='10' value={"10"}></Select.Item>
                                        <Select.Item label='11' value={"11"}></Select.Item>
                                        <Select.Item label='12' value={"12"}></Select.Item>
                                    </Select>
                                    {
                                        console.log(this.state.startTimeMinute)
                                    }
                                </FormControl>
                                <FormControl>
                                    <FormControl.Label>Minutes:</FormControl.Label>
                                    <Select selectedValue={this.state.editStartDate.getMinutes().toString() == "0" ? "00": this.state.editStartDate.getMinutes().toString() }
                                        defaultValue="00"
                                        onValueChange={(minute) => {

                                            this.state.editStartDate.setMinutes(minute)
                                            this.setState({ editStartDate: this.state.editStartDate, startTimeMinute: minute })
                                        }}
                                    >

                                        <Select.Item label='00' value={"00"}>00</Select.Item>
                                        <Select.Item label='15' value={"15"}>15</Select.Item>
                                        <Select.Item label='30' value={"30"}>30</Select.Item>
                                        <Select.Item label='45' value={"45"}>45</Select.Item>
                                    </Select>
                                </FormControl>
                                <FormControl>
                                    <FormControl.Label>AM or PM:</FormControl.Label>
                                    <Select 
                                    defaultValue={this.state.startTimeAMPM} 
                                    onValueChange={(AMPM) => {
                                        if (this.state.editStartDate.getHours() < 12) {
                                            if (AMPM == "PM" && this.state.editStartDate.getHours() < 12) {
                                                this.state.editStartDate.setHours(this.state.editStartDate.getHours() + 12)
                                            }
                                            // else if (AMPM == "AM" && this.state.newStartDate.getHours() >= 12){
                                            //     this.state.newStartDate.setHours(this.state.newStartDate.getHours()-12)
                                            // }
                                            console.log(this.state.editStartDate.getHours())

                                        }
                                        else {
                                            if (AMPM == "AM" && this.state.editStartDate.getHours() >= 12) {
                                                this.state.editStartDate.setHours(this.state.editStartDate.getHours() - 12)
                                            }
                                        }
                                        this.setState({ startTimeAMPM: AMPM, editStartDate: this.state.editStartDate })
                                    }}>
                                        <Select.Item label='AM' value="AM">AM</Select.Item>
                                        <Select.Item label='PM' value="PM">PM</Select.Item>
                                    </Select>

                                </FormControl>
                                {/* {<FormControl>
                                    <FormControl.Label>Hosting Team:</FormControl.Label>
                                    <Select value={this.state.selectedMatch.TeamAName}

                                        onValueChange={(ev) => {
                                            this.state.selectedMatch = { ...this.state.selectedMatch, TeamAName: ev}
                                            this.setState({ selectedMatch: {...this.state.selectedMatch} })
                                        }}
                                        accessibilityLabel="choose values">
                                        {
                                            Object.keys(this.state.teamList).map((key, index) => {
                                                return (
                                                    <Select.Item key={index} value={key} label={this.state.teamList[key].teamName} ></Select.Item>
                                                )
                                            })
                                        }

                                    </Select>
                                </FormControl>
                                {
                                    console.log(this.state.selectedMatch.TeamAName)
                                }
                                <FormControl>
                                    <FormControl.Label>Visiting Team:</FormControl.Label>
                                    <Select value={this.state.selectedMatch.TeamBName}
                                        defaultValue={this.state.selectedMatch.TeamBName}
                                        onValueChange={(ev) => {
                                            this.state.selectedMatch = { ...this.state.selectedMatch, TeamBName: ev}
                                            this.setState({ selectedMatch: {...this.state.selectedMatch} })
                                        }}
                                        accessibilityLabel="choose values">
                                        {
                                            Object.keys(this.state.teamList).map((key, index) => {
                                                return (
                                                    <Select.Item key={index} value={key} label={this.state.teamList[key].teamName} ></Select.Item>
                                                )
                                            })
                                        }

                                    </Select>
                                </FormControl>} */}

                            </Modal.Body>
                            <Modal.Footer>
                                <Button onPress={() => {
                                    firebase.database()
                                    .ref("matches").child(this.state.selectedMatchKey).update({startDate:this.state.editStartDate.toString()})
                                this.setState({ editStartDate: new Date(new Date().setHours(9, 0)), showEditMatch: false })
                                }}  >
                                    <Text>Save</Text>
                                </Button>
                            </Modal.Footer>
                        </Modal.Content>

                    </Modal>


                    <Modal isOpen={this.state.showNewMatch} onClose={() => {
                        console.log("pressed")
                        this.setState({ showNewMatch: false })
                    }}>
                        <Modal.Content>
                            <Modal.CloseButton
                            ></Modal.CloseButton>
                            <Modal.Header><Text>Add New Match</Text></Modal.Header>
                            <Modal.Body>

                                {/* {<FormControl>
                                    <FormControl.Label>Match Name:</FormControl.Label>
                                    <Input
                                        onChangeText={(text) => {
                                            this.setState({ newMatchName: text })
                                        }}
                                        value={this.state.newMatchName}
                                        placeholder='Match Name'></Input>
                                </FormControl>} */}

                                <FormControl>
                                    <FormControl.Label>{"Month:"}</FormControl.Label>

                                    <Select selectedValue={this.state.newStartDate.getMonth()}
                                        onValueChange={(value) => {
                                            this.state.newStartDate.setMonth(value)
                                            this.setState({ newStartDate: this.state.newStartDate })
                                            this.DaysInAMonth(value)
                                        }}
                                        padding={"2"}
                                    //defaultValue={startDate.getMonth()}
                                    >
                                        <Select.Item label={"January"} value={0}></Select.Item>
                                        <Select.Item label={"February"} value={1}></Select.Item>
                                        <Select.Item label={"March"} value={2}></Select.Item>
                                        <Select.Item label={"April"} value={3}></Select.Item>
                                        <Select.Item label={"May"} value={4}></Select.Item>
                                        <Select.Item label={"June"} value={5}></Select.Item>
                                        <Select.Item label={"July"} value={6}></Select.Item>
                                        <Select.Item label={"August"} value={7}></Select.Item>
                                        <Select.Item label={"September"} value={8}></Select.Item>
                                        <Select.Item label={"October"} value={9}></Select.Item>
                                        <Select.Item label={"November"} value={10}></Select.Item>
                                        <Select.Item label={"December"} value={11}></Select.Item>
                                    </Select>

                                </FormControl>
                                <FormControl>
                                    <FormControl.Label>Date:</FormControl.Label>
                                    <Select
                                        onValueChange={(value) => {
                                            this.state.newStartDate.setDate(value)
                                            this.setState({ newStartDate: this.state.newStartDate })

                                        }}
                                        selectedValue={this.state.newStartDate.getDate()} >
                                        {
                                            this.state.monthDayList.map((day) => {
                                                return (
                                                    <Select.Item key={day} label={day} value={day}>{day}</Select.Item>
                                                )
                                            })
                                        }

                                    </Select>
                                </FormControl>
                                {
                                    // <Select
                                    //                             onValueChange={(value) => {
                                    //                                 // console.log(value)
                                    //                                 // startDate.setFullYear(value)
                                    //                                 // console.log(startDate)
                                    //                                 // setStartDate(new Date(startDate))
                                    //                                 competition.setStartDateYear(value)
                                    //                                 setCompetition(new TTNotifyCompetition(competition))

                                    //                             }}

                                    //                             maxW={100} selectedValue={competition.getStartDateYear()}>
                                    //                             <Select.Item value={new Date().getFullYear()} label={new Date().getFullYear()}></Select.Item>
                                    //                             <Select.Item value={new Date().getFullYear() + 1} label={new Date().getFullYear() + 1}></Select.Item>
                                    //                         </Select>
                                }





                                <FormControl>
                                    <FormControl.Label>Hour:</FormControl.Label>
                                    {
                                        console.log(this.state.newStartDate.getHours())
                                    }

                                    <Select value={this.state.newStartDate.getHours()} defaultValue="9"
                                        onValueChange={(hour) => {
                                            this.state.newStartDate.setHours(this.state.startTimeAMPM == "PM" ? parseInt(hour) + 12 : hour)
                                            this.setState({ newStartDate: this.state.newStartDate, startTimeHour: this.state.startTimeAMPM == "PM" ? parseInt(hour) + 12 : hour })
                                            console.log(this.state.startTimeAMPM == "PM" ? parseInt(hour) + 12 : hour)
                                        }} >
                                        <Select.Item label='Select Hour' value=''></Select.Item>
                                        <Select.Item label='1' value={"1"}></Select.Item>
                                        <Select.Item label='2' value={"2"}></Select.Item>
                                        <Select.Item label='3' value={"3"}></Select.Item>
                                        <Select.Item label='4' value={"4"}></Select.Item>
                                        <Select.Item label='5' value={"5"}></Select.Item>
                                        <Select.Item label='6' value={"6"}></Select.Item>
                                        <Select.Item label='7' value={"7"}></Select.Item>
                                        <Select.Item label='8' value={"8"}></Select.Item>
                                        <Select.Item label='9' value={"9"}></Select.Item>
                                        <Select.Item label='10' value={"10"}></Select.Item>
                                        <Select.Item label='11' value={"11"}></Select.Item>
                                        <Select.Item label='12' value={"12"}></Select.Item>
                                    </Select>
                                    {
                                        console.log(this.state.startTimeMinute)
                                    }
                                </FormControl>
                                <FormControl>
                                    <FormControl.Label>Minutes:</FormControl.Label>
                                    <Select value={this.state.startTimeMinute}
                                        defaultValue="00"
                                        onValueChange={(minute) => {

                                            this.state.newStartDate.setMinutes(minute)
                                            this.setState({ newStartDate: this.state.newStartDate, startTimeMinute: minute })
                                        }}
                                    >

                                        <Select.Item label='00' value={"00"}>00</Select.Item>
                                        <Select.Item label='15' value={"15"}>15</Select.Item>
                                        <Select.Item label='30' value={"30"}>30</Select.Item>
                                        <Select.Item label='45' value={"45"}>45</Select.Item>
                                    </Select>
                                </FormControl>
                                <FormControl>
                                    <FormControl.Label>AM or PM:</FormControl.Label>
                                    <Select defaultValue={this.state.startTimeAMPM} onValueChange={(AMPM) => {
                                        if (this.state.newStartDate.getHours() < 12) {
                                            if (AMPM == "PM" && this.state.newStartDate.getHours() < 12) {
                                                this.state.newStartDate.setHours(this.state.newStartDate.getHours() + 12)
                                            }
                                            // else if (AMPM == "AM" && this.state.newStartDate.getHours() >= 12){
                                            //     this.state.newStartDate.setHours(this.state.newStartDate.getHours()-12)
                                            // }
                                            console.log(this.state.newStartDate.getHours())

                                        }
                                        else {
                                            if (AMPM == "AM" && this.state.newStartDate.getHours() >= 12) {
                                                this.state.newStartDate.setHours(this.state.newStartDate.getHours() - 12)
                                            }
                                        }
                                        this.setState({ startTimeAMPM: AMPM, newStartDate: this.state.newStartDate })
                                    }}>
                                        <Select.Item label='AM' value="AM">AM</Select.Item>
                                        <Select.Item label='PM' value="PM">PM</Select.Item>
                                    </Select>

                                </FormControl>
                                <FormControl>
                                    <FormControl.Label>Hosting Team:</FormControl.Label>
                                    <Select value={this.state.newMatchTeamHome}

                                        onValueChange={(ev) => {
                                            console.log(ev)
                                            this.setState({ newMatchTeamHome: ev })
                                        }}
                                        accessibilityLabel="choose values">
                                        {
                                            Object.keys(this.state.teamList).map((key, index) => {
                                                return (
                                                    <Select.Item key={index} value={key} label={this.state.teamList[key].teamName+"("+this.state.teamList[key].teamDivision+")"} ></Select.Item>
                                                )
                                            })
                                        }

                                    </Select>
                                </FormControl>
                                <FormControl>
                                    <FormControl.Label>Visiting Team:</FormControl.Label>
                                    <Select value={this.state.newMatchTeamAway}

                                        onValueChange={(ev) => {
                                            
                                            this.setState({ newMatchTeamAway: ev })
                                        }}
                                        accessibilityLabel="choose values">
                                        {
                                            Object.keys(this.state.teamList).map((key, index) => {
                                                return (
                                                    <Select.Item key={index} value={key} label={this.state.teamList[key].teamName+"("+this.state.teamList[key].teamDivision+")"} ></Select.Item>
                                                )
                                            })
                                        }

                                    </Select>
                                </FormControl>







                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    disabled={ this.state.newMatchTeamHome.length < 1 || this.state.newMatchTeamAway < 1}
                                    onPress={() => {
                                        let newMatch = new League("LA Ping Pong", "Los Angeles", "CA", 1, this.state.newStartDate, "", "", true, this.state.newMatchTeamHome, this.state.newMatchTeamAway, 3, "", "", "", "")
                                        firebase.database()
                                            .ref("matches").push({ ...newMatch, seasonKey: this.props.route.params["seasonKey"],}).then((res) => {
                                                console.log(res)
                                            })
                                        this.setState({ newMatchTeamAway: "", newMatchTeamHome: "", newMatchName: "", newStartDate: new Date(new Date().setHours(9, 0)), showNewMatch: false })
                                    }}
                                >
                                    <Text>Save</Text>
                                </Button>
                            </Modal.Footer>
                        </Modal.Content>

                    </Modal>

                </View>
            </NativeBaseProvider>

        )
    }
}
export default MatchAdmin