import React from 'react';
import { Image, Animated, Easing, Text, ScrollView, FlatList, Button, Linking} from 'react-native'
import { Card } from 'react-native-paper'

const ScheduledMatchItem = (props) =>{
    const {TeamAName, TeamBName, matchName, matchDate, matchDescription, publicStreamURL, awayTeamName, hostTeamName } = props

    function formatAMPM(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
      }

    return(
        <Card key={props.index} style={{borderRadius:15, margin:3, borderColor: "black", borderWidth:2, borderStyle:"solid", alignSelf:"center", maxWidth:600, width:"100%"}}>
            <Card.Title 
            title={hostTeamName + " vs "+ awayTeamName}
            titleStyle={{fontSize:20, justifyContent:"center", alignItems:"center", width:"100%"}}
            ></Card.Title>
            <Card.Content>
                <Text>{["January","February","March","April","May","June","July","August","September","October","November","December"][new Date(props.startDate).getMonth()]+" "+ new Date(props.startDate).getDate()+", "+new Date(props.startDate).getFullYear()+" at "+formatAMPM(new Date(props.startDate))}</Text>
            </Card.Content>
            <Card.Actions style={{justifyContent:"flex-end" }} >
                {
                //   <Button
                //  title={publicStreamURL ? "View Match":"Stream N/A"}
                //  onPress={()=>{
                //      console.log(props["tables"][0])
                //      if(publicStreamURL){
                //         Linking.openURL(publicStreamURL) 
                //      }
                //      else{

                //      }
                     
                //     //props.navigation.navigate("StreamMatch", {...props["tables"][0],seasonid:props.seasonid, divisionid:props.divisionid, matchid:props.matchid, hostTeamID: props.TeamAID, visitorTeamID:props.TeamBID, streamKey: props.streamKey }, {title:"TEst"} )
                //  }}
                //  >
                    
                // </Button>  
                }
                
            </Card.Actions>
            
        </Card>
    )
}
export default ScheduledMatchItem