import axios from "axios"
export default class Team {
    // ({
    //     teamName: newTeamName,
    //     players: newPlayers,
    //     teamDivision: newTeamDivision,
    //     teamStreamKey: this.trimScoreboardURL(newTeamName, seasonName),
    //     teamScoreboardURL: this.trimScoreboardURL(newTeamName, seasonName),
    //     scoreboardPath: this.trimScoreboardURL(newTeamName, seasonName)
    // })
    constructor(teamName, division, players, seasonName){
        this.teamName = teamName
        this.teamDivision = division
        this.players = []
       
        for (const player of players) {
            this.players.push({name:player.firstName})
        }
        
       
        this.teamStreamKey = this.trimScoreboardURL(teamName, seasonName)
         this.teamScoreboardURL= this.trimScoreboardURL(teamName, seasonName)
        this.scoreboardPath=this.trimScoreboardURL(teamName, seasonName)

        this.createStream(this.teamName, seasonName)
    }

    
createStream(teamName, seasonName){
    axios.post("https://live.lapingpong.com:5443/LAPingPong/rest/v2/broadcasts/create", {
                                            "streamId": this.trimScoreboardURL(teamName, seasonName),
                                            "type": "liveStream",
                                            "name": seasonName + " "+teamName,
                                            "description": "",
                                            "publish": true,
                                            "publicStream": true,
                                            "is360": false,
                                            "mp4Enabled": 1
                                        })
}

    trimScoreboardURL(teamName = "", seasonName = "") {
        let parsedTeamName = ""
        let parsedSeasonName = ""
        for (const letter of teamName.trim()) {
            switch (letter) {
                case " ":
                    parsedTeamName += "-"
                    break;
                case "'":
                    break;

                default:
                    parsedTeamName += letter
                    break;
            }
        }
        for (const letter1 of seasonName.trim()) {
            switch (letter1) {
                case " ":
                    parsedSeasonName += "-"
                    break;
                case "'":
                    break;

                default:
                    parsedSeasonName += letter1
                    break;
            }
        }
        return parsedSeasonName + "_" + parsedTeamName
    }
}