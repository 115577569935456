import React from 'react';
import { Image, Animated, Easing, Text, ScrollView, FlatList,  Linking} from 'react-native'
import { Card, Button } from 'react-native-paper'

const MatchItem = (props) =>{
    const {TeamAName, TeamBName, matchName, matchDate, matchDescription, streamKey, streamURL, hostTeamName, awayTeamName, hostTeamScoreboardURL, hostTeamsStreamKey, hostTeamsStreamURL } = props
    console.log(props)
    function formatAMPM(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
      }

    return(
        <Card key={props.index} style={{borderRadius:15, margin:3, maxWidth:600, alignSelf:"center", width:"100%"}}>
            <Card.Title 
            title={hostTeamName + " vs "+ awayTeamName}
            titleStyle={{fontSize:20, justifyContent:"center", alignItems:"center", width:"100%", textAlign:"center", fontWeight:"bold"}}
            ></Card.Title>
            <Card.Content>
                <Text style={{fontSize:20, justifyContent:"center", alignItems:"center", width:"100%", textAlign:"center"}}>{["January","February","March","April","May","June","July","August","September","October","November","December"][new Date(props.startDate).getMonth()]+" "+ new Date(props.startDate).getDate()+", "+new Date(props.startDate).getFullYear()+" at "+formatAMPM(new Date(props.startDate))}</Text>
            </Card.Content>
            <Card.Actions style={{justifyContent:"flex-end" }} >
            <Button 
                 title={"Keep Score"}
                 onPress={()=>{
                     console.log(props["tables"][0])
                     //"StreamMatch"
                    props.navigation.navigate("KeepLeagueScore", {...props["tables"][0],seasonid:props.seasonKey, matchid:props.matchid, hostTeamID: props.TeamAName, visitorTeamID:props.TeamBName, streamKey: hostTeamsStreamKey, streamURL:hostTeamsStreamURL, scoreboardURL: hostTeamScoreboardURL },  )
                 }}
                 >
                    <Text>Keep Score</Text>
                </Button>
                <Button style={{margin:5}}
                 title={"Stream Instructions"}
                 onPress={()=>{
                     console.log(props["tables"][0])
                     //"StreamMatch"
                    props.navigation.navigate("StreamInstructions", {...props["tables"][0],seasonid:props.seasonKey,  matchid:props.matchid, hostTeamID: TeamAName, visitorTeamID:TeamBName, streamKey: hostTeamsStreamKey, streamURL:hostTeamsStreamURL, scoreboardURL: hostTeamScoreboardURL },  )
                 }}
                 >
                    <Text>Stream Instructions</Text>
                </Button>
            </Card.Actions>
            
        </Card>
    )
}
export default MatchItem