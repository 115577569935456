import React from 'react';
import {
  FlatList,
  Image,
  SafeAreaView,
  ScrollView,
  StatusBar,
  StyleSheet,
  Text,
  useColorScheme,
  View,
  //Button,
  TouchableOpacity,
  Linking,
  Platform
} from 'react-native';
import axios from 'axios';
import InstructionItem  from './components/instructionitem';
import { LatestNewsURL } from './settings';
import { LAPPWordPressPosts } from './classes/class.wordpressjson'
import Icon from 'react-native-vector-icons/FontAwesome5';
import LoadingScreen from './login/loadingscreen';
import SpinningBall from './components/spinningloadingball';
import firebase from 'firebase';
import {NativeBaseProvider, Toast, 
 // Card,
   Button} from 'native-base'
import {
  Card,
  //    Button
} from 'react-native-paper'
import { Dialog } from 'react-native-paper';

class StreamInstructions extends React.Component {

  constructor(props){
    super(props)
    this.showToast = this.showToast.bind(this)
  }

  state = {
    instructions:[],
    initialLoadDone: false,
  }

  showToast(){
    console.log("pressed")
    this.setState({showCopied:true})
  }

  componentDidMount() {
    console.log(this.props.route.params)
const {hostTeamID, matchid, seasonid,scoreboardURL, streamKey, streamURL} = this.props.route.params
    firebase.database().ref("seasons").child(seasonid).child("teams").child(hostTeamID).once("value",
    (data)=>{
      var instructions = [
        // {
        //     title:"1. Install Live Prism Studio.",
        //     hasPhoto:false,
        //     photoURL:"",
        //     description:"Download Live Prism Studio on to the streaming device, this app is used to live stream the video.",
        //     hasExternalLink: true,
        //     externalLinkText:"Download App",
        //     externalLink: Platform.OS =="ios" ? "https://apps.apple.com/us/app/prism-live-studio/id1319056339" : "https://play.google.com/store/apps/details?id=com.prism.live&hl=en_US&gl=US",
        //     needsToCopy:false,
        //     copyLink:""
        // },
      //   {
      //     title:"For video instructions, click here..",
      //     hasPhoto:false,
      //     photoURL:"",
      //     description:"",
      //     hasExternalLink: true,
      //     externalLinkText:"Go To Video Instructions",
      //     externalLink: "https://youtube.com",
      //     needsToCopy:false,
      //     copyLink:""
      // },
        {
            title:"2. Create an account/login to Live Prism Studio.",
            //hasPhoto:true,
           // photoURL:require("./img/login.gif"),
           // isLocalPhoto:true,
            description:"",
            needsToCopy:false,
            copyLink:""
        },
        {
            title:"3. Swipe Left, and Tap Widget. Then Web.",
            //hasPhoto:true,
           // photoURL:require("./img/addscoreboardshort.gif"),
           // isLocalPhoto:true,
            description:"",
            needsToCopy:false,
            copyLink:""
        },
        {
            title:"4. Paste this into URL Setting. Then Save.",
            // hasPhoto:true,
            // photoURL:require("./img/paste-scoreboard-url.gif"),
            // isLocalPhoto:true,
            description:"",
            needsToCopy:true,
            copyLink:scoreboardURL
        },
        {
            title:"5. Tap new tile, and adjust window.",
            // hasPhoto:true,
            // photoURL:require("./img/Tapandreadjust.gif"),
            // isLocalPhoto:true,
            description:"",
            needsToCopy:false,
            copyLink:""
        },
        {
            title:"6. Tap Ready, Set Destination, +Add, Custom RTMP.",
            // hasPhoto:true,
            // photoURL:require("./img/prestreamurl.gif"),
            // isLocalPhoto:true,
            description:"",
            needsToCopy:false,
            copyLink:""
        },
        {
            title:"Copy this, paste into Stream URL.",
            hasPhoto:false,
            photoURL:"",
            description:"",
            needsToCopy:true,
            copyLink:streamURL
        },
        {
            title:"Copy this, paste into Stream Key.",
            hasPhoto:false,
            photoURL:"",
            description:"",
            needsToCopy:true,
            copyLink:streamKey
        },
        {
            title:"Hit Save. Ready to Stream!",
            // hasPhoto:true,
            // photoURL:require("./img/save-and-go.gif"),
            // isLocalPhoto:true,
            description:"",
            needsToCopy:false,
            copyLink:""
        },
        
    ]

      this.setState({
        instructions: instructions ,
        streamKey: data.val().streamKey,
        streamURL:data.val().streamURL,
         scoreboardURL: scoreboardURL,
       initialLoadDone:true})

    })

console.log(this.props.route.params)




  }

  render() {
    if (this.state.initialLoadDone) {

    }
    return (
<NativeBaseProvider>
      <View style={{ width: "100%", height: "100%", backgroundColor: "#ff7800" }}>
       
            <Card style={{ borderRadius: 15, margin: 3, backgroundColor: "white",maxWidth:600, alignSelf:"center", width:"100%" }}>
                <Card.Title

                    title={"1. Install Live Prism Studio."}
                    titleStyle={{ fontSize: 14, fontWeight: "bold" }}
                ><Text>GGG</Text></Card.Title>
                <Card.Content>
                  
                    
                            <Button style={{backgroundColor:"#ff7800", margin:5}} onPress={() => {
                                Linking.openURL("https://apps.apple.com/us/app/prism-live-studio/id1319056339")
                            }}>
                                <Text style={{color:"black", fontWeight:"bold"}}>iOS App Store</Text>
                            </Button>
                            <Button style={{backgroundColor:"#ff7800",margin:5}} onPress={() => {
                                Linking.openURL("https://play.google.com/store/apps/details?id=com.prism.live&hl=en_US&gl=US")
                            }}>
                                <Text style={{color:"black", fontWeight:"bold"}}>Play Store</Text>
                            </Button>
                  
                    

                    


                </Card.Content>


            </Card>
       
        {
          this.state.initialLoadDone ?
            <FlatList data={this.state.instructions} renderItem={(data) => {
              return <InstructionItem showToast={this.showToast}  {...data.item}></InstructionItem >
            }} />
            :
            <View style={{ width: "100%", height: "100%", justifyContent: "center", alignItems: "center" }}>
              <SpinningBall></SpinningBall>
            </View>

        }
 {
          this.state.showCopied?
          <Dialog visible={this.state.showCopied} 
          onDismiss={()=>{
            this.setState({showCopied:false})
          }}
          >
            <Dialog.Content style={{justifyContent:"center", alignItems:"center"}}>
            <Text style={{fontSize:30}}>Text Copied!</Text></Dialog.Content>
            <Dialog.Actions>
              <Button title="Close" 
              onPress={()=>{
                this.setState({showCopied:false})
              }}
              ><Text>Close</Text></Button>
            </Dialog.Actions>
          </Dialog>:
          null
        }
      </View>

 </NativeBaseProvider>
    )
  }

}

export default StreamInstructions