import React from 'react';
import {
    FlatList,
    Image,
    SafeAreaView,
    ScrollView,
    StatusBar,
    StyleSheet,
    Text,
    useColorScheme,
    View,
    //Button,
    Linking
} from 'react-native';

import MatchItem from './components/matchitem';
import firebase from 'firebase';
import { Card, Button } from 'react-native-paper';

// const tempData = [{
//     matchName:"My Test Stream",
//     //matchDescription:"Jack's first test of the stream",
//     matchDate: new Date().toISOString(),
//     streamKey: "k8xm-thmt-1usc-e1km-6a3b",
//     streamURL: "rtmp://x.rtmp.youtube.com/live2/",
//     publicStreamURL: "", //when i can get from the api..
// }]

class ChooseMatchList extends React.Component {

    state={
        matchList: [],
        isAdmin: false,
        teamList:[]
    }

    componentDidMount(){
        // Get request for current matches
        //then set to state.

       
        firebase.database().ref("admins").once("value",(admins)=>{
            Object.keys(admins.val()).map((adminKey) =>{
              if(firebase.auth().currentUser.email == admins.val()[adminKey]){
                this.setState({isAdmin:true})
              }
             
            })
          })
        console.log(this.props)
        this.props.navigation.setOptions({ title: 'Match List', headerRight: () => { return(
            <Button
              onPress={() => {
                this.props.navigation.goBack()
                firebase.auth().signOut()}}
              title="Sign Out"
              color="#fff"
            ><Text>Sign Out</Text></Button>)} })
        firebase.database().ref("seasons").limitToLast(1).orderByChild("isActive").equalTo(true).once("value",(data)=>{
            console.log(data.val())


            var seasonId = Object.keys(data.val())[0]
             firebase.database()
            .ref("seasons").child(seasonId).child("teams").on("value", (data) => {
                console.log(data.val())

                this.setState({
                    teamList: data.val() == null ? [] : data.val(),
                })
            })
            firebase.database().ref("matches").orderByChild("seasonKey").equalTo(seasonId).on("value", (match)=>
            {
                if(match.val()){
                    console.log(match.val())
                  var matchList = Object.keys(match.val()).map((matchId, index)=>{
                      return {...match.val()[matchId], matchid: matchId}
                  })
                  this.setState({matchList: matchList})  
                  }
                  else{
                      this.setState({matchList:[], noMatches: true})
                  }
            })
            
        })

       // this.setState({matchList: tempData})
    }

    render(){
        return(<View style={{width:"100%", height:"100%", backgroundColor:"#ff7800"}}>
            {
                this.state.isAdmin ? 
                <Button onPress={()=>{this.props.navigation.navigate("SeasonAdmin")}}><Text>Go To Admin Site.</Text></Button>:
                null
            }
           {this.state.noMatches ? 
            <Card style={{borderRadius:15, margin:3, }}>
                <Text style={{fontSize:24, textAlign:"center"}}>There are no matches scheduled.</Text>
            </Card> : null} 
            <FlatList data={this.state.matchList.sort((a,b)=>{
                                if(a.startDate > b.startDate){
                                    return -1
                                }
                                else {
                                    return 1
                                }
                            })} renderItem={(data) =>{
                console.log(this.state.teamList)
                return(
                    <MatchItem {...data.item} key={data.index} {...this.props} 
                    hostTeamScoreboardURL={this.state.teamList[data.item.TeamAName]? ("https://live.lapingpong.com/live/?t="+this.state.teamList[data.item.TeamAName].scoreboardPath): ""}
                    hostTeamsStreamKey={this.state.teamList[data.item.TeamAName]? this.state.teamList[data.item.TeamAName].scoreboardPath: ""}
                    hostTeamsStreamURL={this.state.teamList[data.item.TeamAName]? "rtmp://live.lapingpong.com/LAPingPong/": ""}
                    hostTeamName={this.state.teamList[data.item.TeamAName]? this.state.teamList[data.item.TeamAName].teamName: ""} 
                    awayTeamName={this.state.teamList[data.item.TeamBName]? this.state.teamList[data.item.TeamBName].teamName: ""} />
                )
            }} />
            
        </View>
            
        )
    }
}
export default ChooseMatchList